import React, { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import CustomRange from "./../form-controls/CustomRange";
import { FiCalendar } from "react-icons/fi";
import MegaDropdown from "../form-controls/MegaDropdown";
import moment from 'moment'
import ReactDatePicker from "react-datepicker";
import useOnClickOutside from 'use-onclickoutside'









import { TaskCategories, TaskDescription, TaskProgress } from './../tasks/TasksComponents';





/*
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/FormGroup';
*/
import { Row, Col, FormGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import FilesList from './../files/FilesList';
import './../tasks/tasks.css';
import LeafCheckbox from "components/form-controls/LeafCheckbox";
const TreeTask = ({ task, onUpdate }) => {









  const updateTask = (params) => {
    onUpdate({ ...task, ...params });
  }




  const treesTasksCategories = useSelector(state => state.config.treesTasksCategories);
  const [viewMode, setViewMode] = useState('list');
  const handleChange = (e) => {
    alert(2)
    console.log(
      e.target.name, e.target.value
    )
    onUpdate({ ...task, [e.target.name]: e.target.value });
  }
  /** Categories */
  const handleOnCategoriesUpdate = (selection) => {
    console.log('handleOnCategoriesUpdate')
    onUpdate({ ...task, tskCategories: selection });
  }
  /** Dates : tskDueDate */
  const [showDatepickers, setShowDatepickers] = useState({ tskDueDate: false });
  const handleDatepickerChange = (key, date) => {
    setShowDatepickers({ ...showDatepickers, [key]: !showDatepickers[key] });
    onUpdate({ ...task, [key]: moment(date).format('YYYY-MM-DD') });
  }
  const toggleDatepickers = (id) => {
    setShowDatepickers({ ...showDatepickers, [id]: !showDatepickers[id] });
  }
  const datepickerTodoOn = useRef(null);
  useOnClickOutside(datepickerTodoOn, () => {
    setShowDatepickers({ ...showDatepickers, tskDueDate: false });
  })
  const datepickerAlertOn = useRef(null);
  useOnClickOutside(datepickerAlertOn, () => {
    setShowDatepickers({ ...showDatepickers, tskAlertOn: false });
  })
  // const [tskDueDate, settskDueDate] = useState(new Date(task.tskDueDate));
  /** Files */
  const handleFilesChange = (new_files) => {
    onUpdate({ ...task, files: new_files })
  }
  return (
    <div className="tree-task">
      <Row>
        <Col sm={4}>
          {/* CATEGORIE */}
          <div className="form-group">
            <label className="form-group-label fw-bolder">Catégories :</label>

            {/* 

            <TaskCategories
              sourceItems={treesTasksCategories}
              selection={task.tskCategories}
              inputType='checkbox'
              onChange={(selection) => updateTask({ tskCategories: selection })}
            />

  */}



          </div>
        </Col>
        <Col sm={6}>
          <div className="form-group">
            <label className="form-group-label fw-bolder">Description :</label>
            <TaskDescription
              value={task.tskText}
              inputClassName="tskText form-control form-control-sm"
              onChange={(e) => updateTask({ tskText: e.target.value })}
              readOnly={false}
              editable={false}
            />








          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="3">
          <div className="form-group">
            <label className="form-group-label fw-bolder">Echéance :</label>
            <div className="mb-2 ms-2">
              <span onClick={() => toggleDatepickers('tskDueDate')} className="pointer" >
                {moment(task.tskDueDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                <span className="px-2"><FiCalendar size="16" style={{ position: 'relative', top: '-2px' }} /></span>
              </span>
              <span ref={datepickerTodoOn}>
                {showDatepickers.tskDueDate == true && <ReactDatePicker
                  dateFormat="dd/MM/yyyy"
                  locale="fr"
                  inline
                  showWeekNumbers
                  closeOnScroll={true}
                  selected={new Date(task.tskDueDate)}
                  onChange={(date) => handleDatepickerChange('tskDueDate', date)
                  }
                >
                  <div style={{ color: "red" }}></div>
                </ReactDatePicker>
                }</span>
            </div>
          </div>
        </Col>
        <Col sm="3">
          <div className="form-group">
            <label className="form-group-label fw-bolder">Alerte :</label>
            <div className="mb-2 ms-2">
              <span onClick={() => toggleDatepickers('tskAlertOn')} className="pointer" >
                {moment(task.tskAlertOn, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                <span className="px-2"><FiCalendar size="16" style={{ position: 'relative', top: '-2px' }} /></span>
              </span>
              <span ref={datepickerAlertOn}>
                {showDatepickers.tskAlertOn == true && <ReactDatePicker
                  dateFormat="dd/MM/yyyy"
                  locale="fr"
                  inline
                  showWeekNumbers
                  closeOnScroll={true}
                  selected={new Date(task.tskAlertOn)}
                  onChange={(date) => handleDatepickerChange('tskAlertOn', date)
                  }
                >
                  <div style={{ color: "red" }}></div>
                </ReactDatePicker>
                }</span>
            </div>
            <div className="mb-2">
              <label>
                <LeafCheckbox
                  checked={task.tskAlertActive == '1'}
                  name='tskAlertActive'
                  onChange={() =>
                    onUpdate({ ...task, tskAlertActive: !task.tskAlertActive })
                  }
                />
                Activer les alertes</label>
            </div>
          </div>
        </Col>
      </Row>
      {false &&
        <ul style={{ backgroundColor: '#eee' }}>
          <li><s>Description</s></li>
          <li><s>Catégories</s></li>
          <li><s>Echeance</s></li>
          <li><s>Rappel</s></li>
          <li><s>Documents</s></li>
          <li>Etat</li>
          <li>Progressions</li>
          <li>Feedback</li>
        </ul>}
      <FilesList
        files={task.files}
        viewMode={viewMode}
        onChange={handleFilesChange}
        onViewModeChange={(vm) => { setViewMode(vm) }}
        uploaderInputProps={{
          accept: {
            'application/pdf': [],
            'image/*': ['.jpeg', '.jpg', '.png'],
          },
          multiple: true,
        }}
      />


      {false && <pre>{JSON.stringify(task, null, 2)}</pre>}
    </div >
  );
}
export default TreeTask;
