import { configureStore } from '@reduxjs/toolkit';
/// import modalReducer from './modalSlice';
/// import progressBarReducer from './progressBarSlice';
import configReducer from './configSlice';
const store = configureStore({
  reducer: {
    /// modal: modalReducer,
    /// progressBar: progressBarReducer,
    config: configReducer,
  },
});
export default store;
