import { useEffect, useState } from 'react';
import FilesList from '../files/FilesList';
import { useFormContext } from 'react-hook-form';
const TreeFormDocuments = ({ parentId }) => {
  const { getValues, setValue } = useFormContext()
  const [viewMode, setViewMode] = useState('list');
  useEffect(() => {
    if (window.localStorage.getItem('treesDocumentsViewMode')) {
      setViewMode(window.localStorage.getItem('treesDocumentsViewMode'));
    }
  }, []);
  const onUploadStart = (file) => {
  };
  const onUploadSuccess = (file) => {
  };
  const onUploadFailure = (file) => {
  };
  const handleDocumentChange = (new_documents) => {
    setValue('documents', new_documents)
  }
  const handleViewModeChange = (vm) => {
    setViewMode(vm)
    window.localStorage.setItem('treesDocumentsViewMode', vm);
  }
  return (
    <div className='tree-pictures'>
      <FilesList
        files={getValues('documents')}
        className="pictures"
        viewMode={viewMode}
        onViewModeChange={handleViewModeChange}
        uploaderInputProps={{
          accept: {
            'application/pdf': [],
            'image/*': ['.jpeg', '.jpg', '.png'],
          },
          multiple: true,
        }}
        onChange={handleDocumentChange}
        onUploadStart={onUploadStart}
        onUploadSuccess={onUploadSuccess}
        onUploadFailure={onUploadFailure}
      />

      {false && <pre>{JSON.stringify(getValues('documents'), null, 2)}</pre>}
    </div>
  );
};
export default TreeFormDocuments;
/*
      <FilesList
        files={getValues('documents')}
        className="pictures"
        viewMode={viewMode}
        onViewModeChange={handleViewModeChange}
        uploaderInputProps={{
          accept: {
            'application/pdf': [],
            'image/*': ['.jpeg', '.jpg', '.png'],
          },
          multiple: true,
        }}
        onChange={handleDocumentChange}
        onUploadStart={onUploadStart}
        onUploadSuccess={onUploadSuccess}
        onUploadFailure={onUploadFailure}
      />
*/