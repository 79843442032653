import React, { useState } from 'react';

const MainMapLeftSide = ({ onClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };
  const handleItemClick = (action) => {
    onClick(action);
  }
  return (
    <aside className={`left-side ${isExpanded ? 'expanded' : 'minimized'}`}>
      <div className='text-end'>
        <span onClick={handleToggleSidebar} className='toggle-sidebar pointer'>
          <i className="left-side-minimizer bi bi-arrow-left-circle" ></i>
        </span>
      </div>
      <div className="sidebar-items">
      </div>
    </aside>
  );
};

export default MainMapLeftSide;
