
// https://reactdatagrid.io/

/* Super mais le calenier est en anglais... */

import React, { TextEditor, key, useState } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import Moment from 'moment'
import '@inovua/reactdatagrid-community/index.css'

import ReactDataGrid_i18n from './../../languages/fr/reactDataGrid.js';
import './lexie.css';
const Datagrid = () => {

  const gridStyle = { minHeight: 550 }

  const columns = [
    /* { name: "tskId", header: "ID", width: '50px' }, */
    { name: "tskTitle", header: "Titre de la tâche", defaultFlex: 2 },
    { name: "tskText", header: "Description", defaultFlex: 3 },
    {
      name: "tskOn",
      header: "Achat",
      defaultFlex: 1,
      dateFormat: 'YYYY-MM-DD',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        return {
          dateFormat: 'DD-MM-YYYY',
          cancelButton: false,
          highlightWeekends: false,
          /* placeholder: index == 1 ? 'Avant' : 'Après' */
        }
      },
      render: ({ value, cellProps }) => {
        return Moment(value).format('DD-MM-YYYY')
      }
    }
  ];

  const dataSource = [
    { tskId: '1', tskTitle: 'Body gris argentée', height: '80px', tskOn: '2023-07-31' },
    { tskId: '2', tskTitle: 'Short bleu marine', tskText: 'Zara', tskOn: '2023-08-01' },
    { tskId: '3', tskTitle: 'Legging noir', tskText: 'simili cuir', tskOn: '2023-08-03' }
  ];


  const filterValue = [
    { name: 'tskTitle', operator: 'startsWith', type: 'string', value: '' },
    /*
    { name: 'age', operator: 'gte', type: 'number', value: 21 },
    { name: 'city', operator: 'startsWith', type: 'string', value: '' },
    */
    {
      name: 'tskOn',
      operator: 'before',
      type: 'date',
      value: ''
    },
    /*
    { name: 'country', operator: 'eq', type: 'select', value: 'ca' }
    */
  ];



  return (
    <div className="lexie-wrapper">
      <h1>Aux merveilles de Lexie !</h1>
      <ReactDataGrid
        i18n={ReactDataGrid_i18n}
        idProperty="tskId"
        columns={columns}
        defaultFilterValue={filterValue}
        dataSource={dataSource}
        style={gridStyle}
      />
      {true && <pre>{JSON.stringify(ReactDataGrid.defaultProps.i18n, null, 2)}</pre>}
    </div>


  );
};

export default Datagrid;


/*
  const columns1 = [
    { name: 'name', header: 'Name', minWidth: 50, defaultFlex: 2 },
    { name: 'age', header: 'Age', maxWidth: 1000, defaultFlex: 1 }
  ]
  const dataSource1 = [
    { id: 1, name: 'John McQueen', age: 35 },
    { id: 2, name: 'Mary Stones', age: 25 },
    { id: 3, name: 'Robert Fil', age: 27 },
    { id: 4, name: 'Roger Robson', age: 81 },
    { id: 5, name: 'Billary Konwik', age: 18 },
    { id: 6, name: 'Bob Martin', age: 18 },
    { id: 7, name: 'Matthew Richardson', age: 54 },
    { id: 8, name: 'Ritchie Peterson', age: 54 },
    { id: 9, name: 'Bryan Martin', age: 40 },
    { id: 10, name: 'Mark Martin', age: 44 },
    { id: 11, name: 'Michelle Sebastian', age: 24 },
    { id: 12, name: 'Michelle Sullivan', age: 61 },
    { id: 13, name: 'Jordan Bike', age: 16 },
    { id: 14, name: 'Nelson Ford', age: 34 },
    { id: 15, name: 'Tim Cheap', age: 3 },
    { id: 16, name: 'Robert Carlson', age: 31 },
    { id: 17, name: 'Johny Perterson', age: 40 }
  ]
  const handleRowClick = (e) => {
    alert(1)
  }
  */