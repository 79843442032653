
const GoogleMapService = {
  getMakersCenter: (markers) => {

    let minLat = markers[0].itData.lat;
    let maxLat = markers[0].itData.lat;
    let minLng = markers[0].itData.lng;
    let maxLng = markers[0].itData.lng;
    markers.forEach(marker => {
      const { lat, lng } = marker.itData;
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    });
    const zoomFactor = 0;
    const latDiff = Math.abs(maxLat - minLat);
    const lngDiff = Math.abs(maxLng - minLng);
    const latZoom = Math.log2(360 / latDiff) - zoomFactor;
    const lngZoom = Math.log2(360 / lngDiff) - zoomFactor;
    const finalZoom = Math.floor(Math.min(latZoom, lngZoom));
    return { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2, zoom: finalZoom };
  }
};
export default GoogleMapService;