// https://reactdatepicker.com/#example-custom-date-format
/* PLUTOT PAS MAL ! */
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale, } from "react-datepicker";
import fr from 'date-fns/locale/fr';
import Moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";
registerLocale('fr', fr)
const DatePicker = () => {

  const [startDate, setStartDate] = useState(new Date());
  const handleDatepickerChange = (date) => {
    console.log(date);
    setStartDate(date);
  }

  const isWeekday = (date) => {

    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  // Obtenir la date de demain en ajoutant un jour à la date actuelle
  const excludeDates = [new Date('2023-08-05'), new Date('2023-08-06')];
  const highlightDates = [new Date('2023-08-07'), new Date('2023-08-08')];
  return (
    <div className="lexie-wrapper" style={{ height: "2000px" }}>
      <h2>Datepicker</h2>
      <ReactDatePicker
        dateFormat="dd/MM/yyyy"
        dropdownMode="select"
        locale="fr"
        showIcon
        showWeekNumbers
        excludeDates={excludeDates}
        highlightDates={highlightDates}
        isClearable
        closeOnScroll={false}
        selected={startDate} onChange={handleDatepickerChange}
        inline
        filterDate={isWeekday}
        showTimeInput
      >
        <div style={{ color: "red" }}>Don't forget to check the weather!</div>
      </ReactDatePicker>
      <pre>{JSON.stringify(startDate)}</pre>
      <pre>{startDate ? startDate.getHours() : '---'}</pre>
    </div>
  );
}
export default DatePicker