// src/components/homes/HomeDefault.js
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import UserService from '../../services/UserService';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import { FaBeer } from 'react-icons/fa';
import './homes.css';
const HomeDefault = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      {UserService.isLogged() &&
        <Navigate to="/main" replace />
      }
      {!UserService.isLogged() &&
        <div className="home home-default">
          <div className='container'>
            <h1 className='text-center'>IF, pour une surveillance et un suivi précis de vos arbres </h1>
            <p><strong>
              Nous sommes ravis de vous présenter notre logiciel de pointe pour la gestion des parcs arborés. Conçu spécialement pour les amoureux de la nature, les responsables d'espaces verts et les professionnels de l'arboriculture, notre solution innovante offre une manière simple, efficace et globale de recenser, suivre et évaluer l'évolution de vos arbres au fil du temps.
            </strong>
            </p>
            <h2>Notre logiciel, une vision globale pour une gestion éclairée :</h2>
            <p>Qu'il s'agisse d'un parc urbain, d'un jardin botanique, d'une propriété privée, ou de tout autre espace vert, notre logiciel vous offre une vision globale de l'état de vos arbres. Grâce à une interface conviviale et intuitive, vous pouvez cartographier l'emplacement de chaque arbre, enregistrer ses caractéristiques principales telles que l'espèce, l'âge, la hauteur et le diamètre du tronc, ainsi que tout autre détail pertinent.</p>
            <h2>Un suivi évolutif pour une gestion proactive :</h2>
            <p>Notre logiciel permet de suivre l'évolution de chaque arbre au fil des saisons et des années. Grâce à une fonction de suivi intelligent, vous pouvez enregistrer des données sur l'état de santé, la croissance, les élagages effectués, et même prendre en compte les aléas climatiques et les maladies potentielles qui pourraient affecter vos arbres.</p>
            <h2>Alertes et recommandations personnalisées :</h2>
            <p>Une des fonctionnalités phares de notre logiciel est la possibilité de recevoir des alertes en temps réel en cas de situations critiques. Lorsqu'un arbre nécessite une attention particulière, que ce soit pour des raisons de sécurité ou de santé, vous recevrez des notifications personnalisées vous indiquant les actions à entreprendre. Cela vous permet de prendre des mesures préventives rapidement et de garantir la préservation de vos arbres dans les meilleures conditions.</p>
            <h2>Un outil pour la collaboration et le partage de données :</h2>
            <p>Notre logiciel de gestion des parcs arborés favorise la collaboration au sein de votre équipe et avec d'autres parties prenantes. Vous pouvez attribuer des rôles et des permissions spécifiques aux membres de votre équipe, partager les données importantes avec les intervenants clés, et ainsi travailler de manière coordonnée pour assurer le bien-être de votre patrimoine arboré.</p>
            <h2>Pour une gestion durable et respectueuse de l'environnement :</h2>
            <p>En optant pour notre logiciel, vous choisissez une approche de gestion durable et responsable de vos arbres. Grâce aux informations précises et à la surveillance continue, vous pouvez prendre des décisions éclairées concernant l'aménagement de vos espaces verts, la planification d'interventions, et l'adoption de pratiques respectueuses de l'environnement.</p>
            <p>En conclusion, notre logiciel de gestion des parcs arborés est l'outil idéal pour les professionnels soucieux de la préservation et de l'épanouissement de leur patrimoine arboré. Vous pourrez ainsi préserver la beauté naturelle de vos espaces verts tout en assurant leur sécurité et leur pérennité pour les générations à venir.</p>
            <p>Rejoignez-nous dès aujourd'hui pour une gestion éclairée de votre domaine arboré et découvrez les avantages de notre solution unique sur le marché.</p>
            <p><em>Ensemble, préservons la nature et prenons soin de nos arbres.</em></p>
          </div>
        </div>


      }
      <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button>


    </div>


  )
};
export default HomeDefault;
