const ReactDataGrid_i18n =
{
  "pageText": "Page ",
  "ofText": " de ",
  "perPageText": "Résultats par page",
  "showingText": "Affichage de ",
  "clearAll": "Tout effacer",
  "clear": "Effacer",
  "showFilteringRow": "Afficher la ligne de filtrage",
  "hideFilteringRow": "Masquer la ligne de filtrage",
  "enable": "Activer",
  "disable": "Désactiver",
  "sortAsc": "Trier par ordre croissant",
  "sortDesc": "Trier par ordre décroissant",
  "unsort": "Annuler le tri",
  "group": "Regrouper",
  "ungroup": "Dégrouper",
  "lockStart": "Verrouiller le début",
  "lockEnd": "Verrouiller la fin",
  "unlock": "Déverrouiller",
  "columns": "Colonnes",
  "autoresizeThisColumn": "Ajuster automatiquement cette colonne",
  "autoresizeAllColumns": "Ajuster automatiquement toutes les colonnes",
  "autoSizeToFit": "Ajuster à la taille",
  "contains": "Contient",
  "startsWith": "Commence par",
  "endsWith": "Se termine par",
  "notContains": "Ne contient pas",
  "inlist": "Dans la liste",
  "notinlist": "Pas dans la liste",
  "neq": "Différent de",
  "inrange": "Dans la plage",
  "notinrange": "Pas dans la plage",
  "eq": "Égal à",
  "notEmpty": "Pas vide",
  "empty": "Vide",
  "lt": "Inférieur à",
  "lte": "Inférieur ou égal à",
  "gt": "Supérieur à",
  "gte": "Supérieur ou égal à",
  "before": "Avant",
  "beforeOrOn": "Avant ou le",
  "afterOrOn": "Après ou le",
  "after": "Après",
  "start": "Début",
  "end": "Fin",
  "createdDateIsAfter": "Soutien-Gorge",
  "calendar.todayButtonText": "Aujourd'hui",
  "calendar.clearButtonText": "Effacer",
  "calendar.okButtonText": "OK",
  "calendar.cancelButtonText": "Annuler",
  "calendar.months": {

  },

  "calendar.todayButtonText": "Aujourd'hui",
  "dragHeaderToGroup": "Faire glisser l'en-tête pour regrouper",
  "noRecords": "Aucun enregistrement disponible"

};




export default ReactDataGrid_i18n;

