import React, { useState } from 'react';
import { HiOutlineViewList, HiOutlineViewGrid } from "react-icons/hi";
import './viewModeToggler.css';
const ViewModeToggler = ({ viewMode = 'grid', onChange }) => {
  const [viewModeCopy, setViewModeCopy] = useState(viewMode);

  const handleViewModeChange = (mode) => {
    setViewModeCopy(mode);
    if (onChange) {
      onChange(mode);
    }
  };
  return (
    <span className='view-mode-toggler'>
      <span
        onClick={() => handleViewModeChange('grid')}
        className={viewModeCopy === 'grid' ? 'active' : ''}
      >
        <HiOutlineViewGrid />
      </span>
      <span
        onClick={() => handleViewModeChange('list')}
        className={viewModeCopy === 'list' ? 'active' : ''}
      >
        <HiOutlineViewList />
      </span>
    </span>
  );
};

export default ViewModeToggler;