import { useEffect, useState } from 'react';
/* import Image from './FilesListImage'; */
import FilesListItem from './FilesListItem';
/* import Document from './FilesListDocument'; */
import FilesViewer from './FilesViewer';
import Uploader from '../helpers/uploader/Uploader';
import './filesList.css';
import ViewModeToggler from '../helpers/viewModeToggler/ViewModeToggler';
import { BiMove } from "react-icons/bi";
const FilesList = ({ files, uploaderInputProps = null, onChange, className = '', viewMode = 'grid', onViewModeChange = null }) => {
  const files_copy = [...files];
  const [dragActive, setDragActive] = useState(false);
  const [fileViewerStartIndex, setFileViewerStartIndex] = useState(-1);
  const [currentViewMode, setCurrentViewMode] = useState('grid');

  const handleDelete = (id) => {
    const filtered = files.filter((file) => file.fileId !== id);
    onChange(filtered);
  };
  const onUploadStart = () => { };
  const onUploadSuccess = (file) => {
    files_copy.push(file);
    onChange(files_copy);
  };
  const onUploadFailure = () => { };
  const handleRotate = async (id) => {
    onChange(files);
  };
  const handleDragStart = (e, index) => {
    // Vérifie si l'événement provient de l'élément "handler"
    if (e.target.classList.contains('draggable-handler')) {
      setDragActive(true);
      e.dataTransfer.setData('text/plain', index);
    } else {
      e.preventDefault(); // Empêche le drag & drop si l'événement ne provient pas de l'élément "handler"
    }
  }
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e, destinationIndex) => {
    e.preventDefault();
    const sourceIndex = e.dataTransfer.getData('text/plain');
    if (sourceIndex !== destinationIndex) {
      const reorderedFiles = Array.from(files);
      const [reorderedItem] = reorderedFiles.splice(sourceIndex, 1);
      reorderedFiles.splice(destinationIndex, 0, reorderedItem);
      onChange(reorderedFiles);
    }
  }
  const handleDragEnd = (e) => {
    e.target.classList.remove('hover');
    setDragActive(false);
  }
  const handleOpen = (file) => {
    const index = files.findIndex((f) => f.fileId === file.fileId);
    setFileViewerStartIndex(index);
  }
  const handleViewModeChange = (vm) => {
    setCurrentViewMode(vm);
    if (onViewModeChange) {
      onViewModeChange(vm)
    }
  }
  return (
    <div className={'files-container ' + className + ' ' + viewMode}>
      <div className='text-end mb-3'>
        <ViewModeToggler viewMode={viewMode} onChange={handleViewModeChange}></ViewModeToggler>
      </div >
      <div className='files-items mb-3'>
        {files.map((file, index) => (
          <div
            key={file.fileId}
            className={' files-list-item' + ' ' + viewMode}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            onDragEnd={(e) => handleDragEnd(e, index)}
          >
            <span className='draggable-handler' draggable onDragStart={(e) => handleDragStart(e, index)} >
              <BiMove />
            </span>
            <FilesListItem
              file={file}
              onDelete={handleDelete}
              onRotate={handleRotate}
              onOpen={handleOpen}
              viewMode={viewMode}
            />
          </div>
        ))}
      </div>
      {
        uploaderInputProps && !dragActive && (
          <Uploader
            inputProps={uploaderInputProps}
            size={files.length === 0 ? 'large' : 'large'}
            onUploadStart={onUploadStart}
            onUploadSuccess={onUploadSuccess}
            onUploadFailure={onUploadFailure}
          />
        )
      }
      {
        fileViewerStartIndex > -1 &&
        <FilesViewer
          files={files}
          index={fileViewerStartIndex}
          onClose={() => setFileViewerStartIndex(-1)}
        />
      }
    </div >
  );
};
export default FilesList;
/*
            {/^image/i.test(file.fileType) ? (
              <FilesListItem
                file={file}
                onDelete={handleDelete}
                onRotate={handleRotate}
                onOpen={handleOpen}
              />
            ) : (
              <Document
                file={file}
                onDelete={handleDelete}
                onOpen={handleOpen}
              />
            )}
  onChange={(mode) => setViewMode(mode)}
<div
            key={file.fileId}
            className={' files-list-item' + ' ' + viewMode}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            onDragEnd={(e) => handleDragEnd(e, index)}
          >
            <FilesListItem
              file={file}
              onDelete={handleDelete}
              onRotate={handleRotate}
              onOpen={handleOpen}
              viewMode={viewMode}
            />
            */