import { useEffect } from 'react';
import DefaultNavbar from '../components/navbars/DefaultNavbar';
import './defaultLayout.css';
const Layout = ({ children }) => {


  useEffect(() => {
    document.body.classList.add('default-layout');
    return () => {
      document.body.classList.remove('default-layout');
    };
  }, []);




  return (
    <div>
      <DefaultNavbar />
      <div className="content default-content">
        {children}
      </div>
    </div>
  );
};

export default Layout;