import React, { useEffect, useState, useRef } from 'react';
import API from './../../api';
import FileEditWordings from './FileEditWordings';
import { TfiNewWindow, TfiInfoAlt } from 'react-icons/tfi';
import { GrRotateRight, GrTrash } from 'react-icons/gr';
import { FiEdit2 } from "react-icons/fi";
import './filesListItem.css';
const PictureComponent = ({
  file,
  onDelete = null,
  onRotate = null,
  onOpen = null,
  viewMode = "grid",
}) => {
  const [fileContent, setFileContent] = useState('');
  const [fileType, setFileType] = useState('');
  useEffect(() => {
    setFileType(file.fileType.startsWith('image') ? 'picture' : 'document');
    API.get('/file/thumb/' + file.fileId).then(response => {
      setFileContent('data:image/jpeg;base64,' + response.data.contents);
    });
  }, [])
  const [editWordings, setEditWordings] = useState(false); // État pour gérer le mode "editWordings"
  const handleEditWordingsClick = (e) => {
    setEditWordings(true);
    e.stopPropagation();
  };
  const handleEditWordingsUpdate = (editedFileName, editedFileComment) => {
    file.fileName = editedFileName;
    file.fileComment = editedFileComment;
    setEditWordings(false);
  };
  const handleEditWordingsCancel = () => {
    setEditWordings(false);
  };
  const handleDelete = () => {
    onDelete(file.fileId);
  };
  useEffect(() => {
    setEditWordings(false)
  }, [viewMode])
  const handleRotate = async (id) => {
    try {
      const response = await API.put('/file/rotate/' + file.fileId, {
        angle: -90
      });
      setFileContent('data:image/jpeg;base64,' + response.data.contents);
      onRotate(file.fileId);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div
    >
      <img src={fileContent} alt='' className='cover' />
      {viewMode === 'grid' &&
        <div className='name'>{file.fileName}</div>
      }
      {viewMode === 'list' &&
        <div className={'wordings ' + (editWordings ? 'editing' : '')}>
          {editWordings ? (
            <FileEditWordings
              file={file}
              onUpdate={handleEditWordingsUpdate}
              onClose={handleEditWordingsCancel}
            />
          ) : (
            <React.Fragment>
              <h6>{file.fileName}
                <span onClick={handleEditWordingsClick}  >
                  <FiEdit2 />
                </span>
              </h6>
              <div className='comment'>{file.fileComment}</div>
            </React.Fragment>
          )}
        </div>
      }
      {viewMode === 'list' &&
        <ul className='infos'>
          {file.prettyCreatedAt && <li>Création : {file.prettyCreatedAt}</li>}
          {file.prettyFileDate && <li>Date fichier : {file.prettyFileDate}</li>}
          {file.prettyFileSize && <li>Poids : {file.prettyFileSize}</li>}
        </ul>
      }
      {fileType === 'document' && <img src='/icons/pdf-32.png' className='icon' alt='' />}
      <ul className='tools'>
        {viewMode == 'grid' && <li>
          <span
            onClick={handleEditWordingsClick}
            title="Afficher les informations"
          >
            <TfiInfoAlt />
          </span>
        </li>}
        <li>
          <span
            onClick={() => onOpen(file)}
            title="Afficher en plein écran"
          >
            <TfiNewWindow />
          </span>
        </li>
        {fileType === 'picture' && (
          <li>
            <span
              onClick={() => handleRotate(file.fileId)}
              title="Retourner de 45°"
            >
              <GrRotateRight />
            </span>
          </li>
        )}
        <li>
          <span
            onClick={() => handleDelete(file.fileId)}
            title="Supprimer"
          >
            <GrTrash />
          </span>
        </li>
      </ul>
      {viewMode == 'grid' && editWordings && <div className='edit-wordings-wrapper'>
        <FileEditWordings
          file={file}
          labels={true}
          onUpdate={handleEditWordingsUpdate}
          onClose={handleEditWordingsCancel}
        />
      </div>}
    </div>
  );
};
export default PictureComponent;
