import React, { useEffect, useState } from 'react';

const Address = ({ address, editable = false, onUpdate }) => {
  const [addressCopy, setAddressCopy] = useState(address);
  useEffect(() => {
    setAddressCopy(address);

  }, [address]);
  const handleChange = (e) => {

    onUpdate({ ...address, [e.target.name]: e.target.value });
    return;
    /*
    const address = { ...addressCopy };
    address.verified = true;
    const { name, value } = e.target;
    address[name] = value
    setAddressCopy(address);

    onUpdate(address)
    */
  };
  return (
    <div className="address">
      <div className='row mb-3' >
        <div className='col-3 address-item street-number'>
          {editable ?
            <input name='streetNumber' className='form-control form-control-sm' type="text" value={address.streetNumber} onChange={handleChange} placeholder='Numéro' />
            :
            address.streetNumber
          }
        </div>
        <div className='col-9  address-item streetName'>
          {editable ?
            <input name='streetName' className='form-control form-control-sm' type="text" value={address.streetName} onChange={handleChange} placeholder='Rue' />
            :
            address.streetName
          }
        </div>
      </div>
      <div className='row  mb-3' >
        <div className='col-3 address-item postal-code'>
          {editable ?
            <input className='form-control form-control-sm' type="text" name='postalCode' value={address.postalCode} onChange={handleChange} placeholder='CP' />
            :
            address.postalCode
          }
        </div>
        <div className='col-9 address-item city'>
          {editable ?
            <input className='form-control form-control-sm' type="text" name='city' defaultValue={address.city} onChange={handleChange} placeholder='Ville' />
            :
            address.city}
        </div>
      </div>
      <pre>{false && JSON.stringify(address, null, 2)}</pre>
      {address.verified !== true && (
        <div className="alert alert-warning mb-2 small pt-1 pr-1 pb-1 pl-1">
          L'adresse est retournée pour un système de géolocalisation automatique et peut contenir des erreurs. Veuillez la vérifier.
        </div>)}
    </div >
  );
};

export default Address;
