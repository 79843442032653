import { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import API from './../../api';
import './filesViewer.css';
import TouchContainer from './../helpers/touchContainer/TouchContainer';
import DrawingLayer from './../helpers/drawingLayer/DrawingLayer';
import { BiCaretLeft, BiCaretRight } from 'react-icons/bi';
const FilesViewer = ({ files, index = 0, onSelect = null, onClose }) => {
  const iframeRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(index);
  const [currentContent, setCurrentContent] = useState('');
  const contentRef = useRef(null);
  useEffect(() => {
    setCurrentContent('');
    setShowDrawingLayer(false);
    setImgNaturalWidth(null);
    setCanvasFile(null);
    API.get('/file/' + files[currentIndex].fileId).then((response) => {
      setCurrentContent('data:' + files[currentIndex].fileType + ';base64,' + response.data.contents);
    });
    if (files[currentIndex].fileType.match(/^image/)) {

    }
  }, [currentIndex]);
  const handleIframeLoad = () => {
    const fileName = files[currentIndex].fileName;
    const iframeWindow = iframeRef.current.contentWindow;
    iframeWindow.postMessage({ type: "file-name", fileName }, "*");
  }
  const handleNav = (delta) => {
    let newIndex = currentIndex + delta;
    if (newIndex < 0) {
      newIndex = files.length - 1;
    }
    if (newIndex >= files.length) {
      newIndex = 0;
    }
    setCurrentIndex(newIndex);
  };
  const handleFileSelection = (selectedFile) => {
    if (onSelect) {
      onSelect(selectedFile);
    }
  };
  /** POUR DRAWING  */
  const imgRef = useRef(null);
  const [showDrawingLayer, setShowDrawingLayer] = useState(false);
  const [imgWidth, setImgWidth] = useState(null);
  const [imgHeight, setImgHeight] = useState(null);
  const [imgNaturalWidth, setImgNaturalWidth] = useState(null);
  const [canvasFile, setCanvasFile] = useState(null);
  const handleOnUpdate = (data) => {
    if (canvasFile === null) {
      API.post('/file', {
        content: JSON.stringify(data),
        fileName: files[currentIndex].fileName + '.json',
        fileCategory: 'canvas',
        fileParentId: files[currentIndex].fileId,
      }).then(response => {
        setCanvasFile(response.data)
      })
    } else {
      API.put('/file/' + canvasFile.fileId, {
        contents: JSON.stringify(data),
      });
    }
  }
  const handleImageLoad = () => {

    if (imgRef.current) {



      const { naturalWidth, naturalHeight, width, height } = imgRef.current;
      console.log(height)
      setImgNaturalWidth(naturalWidth);
      setImgWidth(width);
      setImgHeight(height);
      window.setTimeout(() => {
        setShowDrawingLayer(true);
      }, 100)
    }
  };
  const currentFile = files[currentIndex];
  return ReactDOM.createPortal(
    <div className="files-viewer">
      <span onClick={onClose} className="close">
        &times;
      </span>
      <div className="inner">

        <div className="content" ref={contentRef}>
          <TouchContainer onSwipeLeft={() => handleNav(-1)} onSwipeRight={() => handleNav(+1)}>
            {currentContent === '' && (
              <div className="spinner-border" aria-hidden="true" style={{ width: '3rem', height: '3rem' }} />
            )}
            {currentContent !== '' && (
              <>
                {/^image/.test(files[currentIndex].fileType) ? (
                  <div className="image-wrapper">
                    <img
                      ref={imgRef}
                      src={currentContent}
                      alt={files[currentIndex].fileName}
                      onLoad={handleImageLoad}
                      style={{ maxWidth_: contentRef.current.offsetWidth, maxHeight: contentRef.current.offsetHeight }}
                    />

                    {true && showDrawingLayer && imgWidth && imgHeight && (
                      <DrawingLayer
                        parentId={files[currentIndex].fileId}
                        width={imgWidth}
                        height={imgHeight}

                        scale={imgWidth / imgNaturalWidth}
                        onUpdate={handleOnUpdate}
                      />

                    )}
                  </div>
                ) : files[currentIndex].fileType === 'application/pdf' ? (
                  <iframe src={currentContent} title={files[currentIndex].fileName} ref={iframeRef} onLoad={handleIframeLoad} />
                ) : (
                  <p>Type de fichier non pris en charge</p>
                )}
              </>
            )}
          </TouchContainer>
        </div>
        <div className="infos">
          <ul>
            {files[currentIndex].prettyCreatedAt && <li>Crée le {files[currentIndex].prettyCreatedAt}</li>}
            {files[currentIndex].prettyFileDate && <li>Date fichier : {files[currentIndex].prettyFileDate}</li>}
            {files[currentIndex].prettyFileSize && <li>Poids : {files[currentIndex].prettyFileSize}</li>}
            {imgNaturalWidth && <li>Taille : {imgNaturalWidth}px X {parseInt(imgNaturalWidth * imgHeight / imgWidth)}px</li>}
          </ul>
          <ul>
          </ul>
          <ul>
          </ul>
        </div>
      </div>
      <span
        type="button"
        className="nav"
        onClick={() => handleNav(-1)}
      >
        <BiCaretLeft />
      </span>
      <span
        type="button"
        className="nav next"
        onClick={() => handleNav(1)}
      >
        <BiCaretRight />
      </span>
      {onSelect && (
        <button onClick={() => handleFileSelection(currentFile)}>Sélectionner</button>
      )}
      <h2>{files[currentIndex].fileName}</h2>
    </div >,
    document.getElementById('files-viewer-modal-root')
  );
};
export default FilesViewer;
/**
 * create thr files-viwer wrapper
 * 
 */
const filesViewerModalRootId = 'files-viewer-modal-root'
if (document.getElementById(filesViewerModalRootId)) {
  document.getElementById(filesViewerModalRootId).remove();
}
const filesViewerModalRoot = document.createElement('div');
filesViewerModalRoot.id = filesViewerModalRootId;
document.body.appendChild(filesViewerModalRoot);
