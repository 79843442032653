import React, { useEffect, useState, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside'
import { IoClose } from 'react-icons/io5';
import { FiCheck } from "react-icons/fi";

import { PiList, PiListChecks, PiCaretDownFill } from "react-icons/pi";
import LeafCheckbox from './LeafCheckbox'
import LeafRadio from './LeafRadio'
import './megaDropdown.css'
const MegaDropdown = ({ sourceItems, inputType = 'checkbox', title, selection, onUpdate, iconSize = 30, iconItemKey = '', pinColorItemKey = '' }) => {
  const [open, setOpen] = useState(false);
  const [selectionCopy, setSelectionCopy] = useState(selection);

  useEffect(() => {
    console.log('selectionCopy', selectionCopy)
  }, [selectionCopy])

  const handleItemClick = (id) => {
    switch (inputType) {
      case 'checkbox':
        if (!selectionCopy.includes(id)) {
          setSelectionCopy([...selectionCopy, id]);
        } else {
          setSelectionCopy(selectionCopy.filter((itemId) => itemId !== id));
        }

        break;
      case 'radio':
        // setSelectionCopy(id);
        onUpdate(id);
        setOpen(false);
        break;
    }
  };
  const handleCheckAll = (all) => {
    if (all == false) {
      setSelectionCopy([]);
    } else {
      const select_all = []
      sourceItems.forEach(item => {
        select_all.push(item.id);
      });
      setSelectionCopy([...select_all]);
    }
  }
  const handleReturnSelection = () => {
    onUpdate(selectionCopy);
    setOpen(false);
  }
  window.setInterval(() => {
  }, 3000)
  const dropdownRef = useRef(null)
  useOnClickOutside(dropdownRef, () => {
    setOpen(false)
  })
  return (
    <div className={`mega-dropdown dropdown ${open ? 'show' : ''}`}>
      <div className="d-flex">
        <div className="flex-grow text-start" style={{ flex: '1' }}>
          {title}
        </div>
        <span
          style={{ top: '-3px' }}
          className="dropdown-toggle d-inline-block position-relative"
          type="button"
          onClick={() => setOpen(!open)}
        >
          <PiCaretDownFill size={iconSize} />
        </span>

      </div>


      <ul ref={dropdownRef} className={`dropdown-menu ${open ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">
        <li className='tools text-end px-2'>
          {inputType == 'checkbox' &&
            <>
              <PiListChecks size="20"
                className="pointer"
                onClick={() => handleCheckAll(true)}
              />
              <PiList size="20"
                className="mx-2 pointer"
                onClick={() => handleCheckAll(false)}
              />
              <FiCheck size="20" className="mx-2 pointer" onClick={() => handleReturnSelection(false)} />
            </>
          }
          <IoClose size="20" className="pointer" onClick={() => setOpen(false)} />
        </li>
        {sourceItems.map((item) => (
          <li key={item.id} className='' >
            <label className={` dropdown-item px-2  ${selectionCopy.includes(item.id) ? 'selected' : ''}`}>

              <LeafCheckbox
                checked={selectionCopy.includes(item.id)}
                onChange={() => handleItemClick(item.id)}
                className='mr-1'
                lexie_icon={iconItemKey != '' && item[iconItemKey] ? item[iconItemKey] : ''}
                pinColor={pinColorItemKey != '' && item[pinColorItemKey] ? item[pinColorItemKey] : ''}
                style={{ width: '24px' }}
              />
              {/* false && inputType == 'radio' && <LeafRadio
                checked={selectionCopy == item.id}
                onChange={() => handleItemClick(item.id)}
                lexie_icon={iconItemKey != '' && item[iconItemKey] ? item[iconItemKey] : ''}

                className='mr-1'
                style={{ width: '24px' }}
        /> */}

              <span>
                {item.text}
              </span>
            </label>
          </li>
        ))}
      </ul>
    </div >
  );
};
export default MegaDropdown;
