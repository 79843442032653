
// src/components/trees/TreeForm.js
import { useEffect, useState, useRef } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import api from './../../api';
import './../../css/form.css';

import './treeForm.css';
import TreeFormGeneralInformations from './TreeFormGeneralInformations';
import TreeFormPictures from './TreeFormPictures';
import TreeFormDocuments from './TreeFormDocuments';
import TreeFormTasks from './TreeFormTasks';
const TreeForm = ({ tree, onSaved }) => {
  const [activeTab, setActiveTab] = useState('general');
  const methods = useForm({ defaultValues: tree });

  const { handleSubmit, watch, reset, formState } = methods;
  useEffect(() => {
    reset(tree);
  }, [reset]);
  const formData = watch();
  const { isDirty, isValid } = formState;
  const handleSave = async () => {
    const response = await api.put('/tree/' + formData.itId, formData);
    reset(response.data)
    onSaved(response.data)
  }
  const handleOnSubmit = async (e) => {
    const response = await api.put('/tree/' + formData.itId, formData);
    reset(response.data)
    onSaved(response.data)
  }
  useEffect(() => {
    // la modification d'objets profonds n'a pas d'effet sur "formState.isDirty" mais est bien detectée par ce useEffect !
    // console.log('isDirty', formState.isDirty)
  }, [formState]);
  return (
    <div className='tree-form'>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleOnSubmit)} >
          <div className='d-flex flex-wrap justify-content-between'>
            <ul className="nav nav-tabs flex-grow-1" style={{ borderBottom: 'none' }} >
              <li className={`nav-item ${activeTab === 'general' ? 'active' : ''}`}>
                <button type='button' className="nav-link" onClick={() => setActiveTab('general')}>
                  Information générales
                </button>
              </li>
              <li className={`nav-item ${activeTab === 'visite' ? 'active' : ''}`}>
                <button type='button' className="nav-link" onClick={() => setActiveTab('visites')}>
                  Visites
                </button>
              </li>
              <li className={`nav-item ${activeTab === 'tasks' ? 'active' : ''}`}>
                <button type='button' className="nav-link" onClick={() => setActiveTab('tasks')}>
                  Tâches
                </button>
              </li>
              <li className={`nav-item ${activeTab === 'pictures' ? 'active' : ''}`}>
                <button type='button' className="nav-link" onClick={() => setActiveTab('pictures')}>
                  Photos
                </button>
              </li>
              <li className={`nav-item ${activeTab === 'documents' ? 'active' : ''}`}>
                <button type='button' className="nav-link" onClick={() => setActiveTab('documents')}>
                  Documents
                </button>
              </li>
              {false && <li className={`nav-item ${activeTab === 'debug' ? 'active' : ''}`}>
                <button type='button' className="nav-link" onClick={() => setActiveTab('debug')}>
                  Debug
                </button>
              </li>}
            </ul>
            {/*  SAVE  */}
            <button
              type="submit"
              className={`btn btn-secondary mb-1 ${formState.isDirty ? 'btn-secondary' : ''}`}
              disabled={formState.isSubmitting}
            >
              {formState.isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" style={{ marginRight: '0.3rem', top: '1px', position: 'relative' }} ></span>
              )}
              Sauvegarder
            </button>
          </div>
          <div className={`tab-content ${activeTab === 'debug' ? 'active' : ''}`}>
            <pre>{JSON.stringify(formData, null, 2)}</pre>
          </div>
          <div className={`tab-content ${activeTab === 'general' ? 'active' : ''}`}>
            <TreeFormGeneralInformations data='itData' />
          </div>
          <div className={`tab-content ${activeTab === 'tasks' ? 'active' : ''}`}>
            <div>
              <TreeFormTasks
                parentId={formData.itId}
              /></div>{false && <pre>{JSON.stringify(formData.tasks)}</pre>}
          </div>
          <div className={`tab-content ${activeTab === 'pictures' ? 'active' : ''}`}>
            <div>
              <TreeFormPictures
                parentId={formData.itId}
              /></div>{false && <pre>{JSON.stringify(formData.pictures)}</pre>}
          </div>
          <div className={`tab-content ${activeTab === 'documents' ? 'active' : ''}`}>
            <div>
              <TreeFormDocuments
                parentId={formData.itId}
              /></div>
          </div>
          {true && <pre>{JSON.stringify(formState.dirtyFields, null, 2)}</pre>}
          {false && <pre>{JSON.stringify(formData, null, 2)}</pre>}
        </form >
      </FormProvider>
    </div >
  );
};
function NavTabs(props) {
  const { click } = props;
  return (
    <button onClick={click}>TEST</button>
  )
}
export default TreeForm;



/*




        <div className={`tab-content ${activeTab === 'visites' ? 'active' : ''}`}>
          <div><h5>Liste des visites faites</h5><p>Dev en cours</p></div>
        </div>


        <div className={`tab-content ${activeTab === 'tasks' ? 'active' : ''}`}>
          <div><h5>Liste des taches effectuées ou à effectuer</h5><p>Dev en cours</p></div>
        </div>




        <div className={`tab-content ${activeTab === 'documents' ? 'active' : ''}`}>
          <div>

            <TreeFormDocuments
              parentId={formData.itId}
              documents={formData.documents}
              onUpdate={(data) => handleOnUpdate('documents', data)}
            /></div>
        </div>







*/
