import React, { useState } from 'react';
import './markers.css';
import { PiLockOpenFill, PiLockSimpleFill } from "react-icons/pi";
const MarkerContextMenu = ({ top, left, marker, onClick }) => {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const colors = ['blue', 'green', 'yellow', 'orange', 'red', 'purple'];
  const handleClick = (action, args = null) => {
    onClick(marker, action, args);
  };
  return (
    <div
      id="marker-context-menu"
      style={{ top, left }}
      className="context-menu"
    > <div className="text-end">
        <span className='close pointer' onClick={() => handleClick('close')}>&times;</span> </div>
      <ul>
        <li onClick={() => handleClick('toggleLock')} className='pointer'>
          {marker.unlocked ? (
            <><PiLockSimpleFill size={18} className="pe-1" />Vérouiller
            </>
          ) : (
            <><PiLockOpenFill size={18} className="pe-1" />Dévérouiller
            </>
          )}
        </li>


        <li onClick={() => handleClick('edit')} className='pointer'><i className="bi bi-info-circle"></i> Informations</li>
        <li onClick={() => handleClick('duplicate')} className='pointer'><i className="bi bi-back"></i> Dupliquer</li>
        {confirmDelete === false &&
          <li onClick={() => setConfirmDelete(true)} className='pointer'><i className="bi bi-trash"></i> Supprimer</li>
        }
        {confirmDelete === true &&
          <li className='pointer text-end'>
            <span className='btn btn-xs btn-danger' onClick={() => handleClick('delete')} >OK</span>

            <span className='btn btn-xs btn-secondary' onClick={() => setConfirmDelete(false)}>Annuler</span>
          </li>
        }
      </ul>
      <ul className='colors' style={{ opacity: '0.2' }}>
        {colors.map((color, index) => (
          <li key={index} className='pointer'>
            {/* Utiliser un span avec un style pour créer le cercle coloré */}
            <span
              className='color-circle'
              style={{ backgroundColor: color }}
              onClick={() => handleClick('color', color)}
            ></span>
          </li>))}
      </ul>

    </div >
  );
};

export default MarkerContextMenu;