import { useEffect } from 'react';
import AuthGuard from './../AuthGuard';
import MainNavbar from '../components/navbars/MainNavbar';
import './mainLayout.css';
const MainLayout = ({ children }) => {

  useEffect(() => {
    document.body.classList.add('main-layout');
    return () => {
      document.body.classList.remove('main-layout');
    };
  }, []);


  return (
    <div>
      <AuthGuard>
        <MainNavbar />
        <div className="content main-content">
          {children}
        </div>
      </AuthGuard>
    </div>
  );
};

export default MainLayout;