import React, { useEffect, useState, useRef } from 'react';
import diacritics from 'diacritics';
import './autocompleteInput.css';
const AutocompleteInput = ({ suggestionsSrc, defaultValue, onSuggestionSelect, onChange, name, displayKey, formatSuggestion }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const suggestionsContainerRef = useRef(null);
  const normalizeText = (text) => diacritics.remove(text.toLowerCase());
  const handleInputChange = (event) => {
    const value = event.target.value;
    onChange(event);
    const normalizedValue = normalizeText(value);
    const filteredSuggestions = suggestionsSrc.filter(
      (tree) => normalizeText(tree[displayKey]).indexOf(normalizedValue) === 0
    );
    setSuggestions(filteredSuggestions);
    setSelectedSuggestionIndex(-1);
  };
  const handleSuggestionClick = (suggestion) => {
    if (suggestion && suggestion[displayKey]) {
      setSuggestions([]);
      onSuggestionSelect(name, suggestion);
      setSelectedSuggestionIndex(-1);
    }
  };
  const handleOnBlur = (event) => {
    window.setTimeout(() => {
      setSuggestions([]);
      setSelectedSuggestionIndex(-1);
    }, 500);
  };
  const scrollToSelectedSuggestion = (index) => {
    if (index >= 0 && suggestionsContainerRef.current) {
      const suggestionElement = suggestionsContainerRef.current.children[index];
      if (suggestionElement) {
        suggestionsContainerRef.current.scrollTop = suggestionElement.offsetTop;
      }
    }
  };
  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (suggestions.length > 0) {
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex <= 0 ? suggestions.length - 1 : prevIndex - 1
          );
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (suggestions.length > 0) {
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex >= suggestions.length - 1 ? 0 : prevIndex + 1
          );
        }
        break;
      case 'Enter':
      case ' ':
        event.preventDefault();
        if (suggestions.length > 0) {
          handleSuggestionClick(suggestions[selectedSuggestionIndex]);
        }
        break;
      case 'Escape':
        setSuggestions([]);
        setSelectedSuggestionIndex(-1); // Réinitialiser l'index de la suggestion sélectionnée lors de la fermeture
        break;
      default:
        break;
    }
  };
  const handleClickOutside = (event) => {
    if (suggestionsContainerRef.current && !suggestionsContainerRef.current.contains(event.target)) {
      setSuggestions([]);
      setSelectedSuggestionIndex(-1);
    }
  };
  useEffect(() => {
    setInputValue(defaultValue || '');
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [defaultValue]);
  useEffect(() => {
    scrollToSelectedSuggestion(selectedSuggestionIndex);
  }, [selectedSuggestionIndex]);
  return (
    <div className='autocomplete-input '>
      <input
        type="text"
        name={name}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        placeholder=""
        value={inputValue}
        className='form-control'
      />

      {suggestions.length > 0 && (
        <div className="autocomplete-container" ref={suggestionsContainerRef}>
          {suggestions.map((tree, index) => (
            <div
              key={tree.id}
              className={`suggestion ${index === selectedSuggestionIndex ? 'selected' : ''}`}
              onClick={() => handleSuggestionClick(tree)}
            >
              {formatSuggestion(name, tree)}
            </div>
          ))}
        </div>
      )}

    </div>
  );
};
export default AutocompleteInput;
