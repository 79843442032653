import React from 'react';
import './navbars.css';
const DefaultNavbar = () => {
  return (
    <nav className="navbar default-navbar">
      <div className="container-fluid">
        <div className="links left-links">
        </div>
        <div className="logo">
          <a href="/" title="">
            <img src="/img/logo.png" alt="IF" />
          </a>
        </div>
        <div className="links right-links">
          <a className='btn btn-sm btn-secondary' href="/login">Connexion</a>
        </div>
      </div>
    </nav>
  );
};
export default DefaultNavbar;
