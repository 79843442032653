// src/App.js

import { useEffect } from 'react';
import HomeDefault from './components/homes/HomeDefault';
import HomeMain from './components/homes/HomeMain';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { useDispatch } from 'react-redux';
import { getConfig } from './redux/configSlice';



import DefaultLayout from './layouts/DefaultLayout';
import MainLayout from './layouts/MainLayout';
import LoginComponent from './components/login/Login';
import ReactHookForm from './components/crashTests/ReactHookForm';
import MainMap from './components/maps/MainMap';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FaBeer } from 'react-icons/fa';
import './css/App.css';
import './css/modal.css';
/* crash test */
import Datagrid from './components/crashTests/Datagrid';
import Datepicker from './components/crashTests/Datepicker';
// import Dropdown from './components/crashTests/Dropdown';
import Filters from './components/crashTests/Filters';
import Drawing from './components/crashTests/Drawing';
import ImageEditor from 'components/crashTests/ImageEditor';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    // Dispatch getConfig au chargement de l'application
    dispatch(getConfig());
  }, [dispatch]);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DefaultLayout><HomeDefault /></DefaultLayout>} />
        <Route path="/login" element={<DefaultLayout><LoginComponent /></DefaultLayout>} />
        <Route path="/main" element={<MainLayout><HomeMain /></MainLayout>} />
        <Route path="/map" element={<MainLayout><MainMap /></MainLayout>} />
        {/* Route pour afficher les détails de l'arbre dans la modal */}
        {/* crashtests */}
        <Route path="/datagrid" element={<DefaultLayout><Datagrid /></DefaultLayout>} />
        <Route path="/datepicker" element={<DefaultLayout><Datepicker /></DefaultLayout>} />
        <Route path="/filters" element={<DefaultLayout><div className='lexie-wrapper'><Filters /></div></DefaultLayout>} />
        <Route path="/drawing" element={<DefaultLayout><div className='lexie-wrapper'><Drawing /></div></DefaultLayout>} />



      </Routes>
    </Router>

  );
}

export default App;