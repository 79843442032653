
import React, { useEffect, useState, useRef } from "react";
import './../tasks/tasks.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import TasksListItem from './TreeTasksListItem';
const Tasks = ({ tasks, onUpdate, onSelect = null }) => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const handleCreateTask = () => {
  }
  const handleTaskUpdate = (task) => {

    const tasks_copy = tasks.map((t) => (t.tskId === task.tskId ? task : t));
    onUpdate(tasks_copy);
  }
  const handleOnSelect = (task) => {
    if (typeof onSelect == 'function') {
      onSelect(task)
    }
  }




  const handleOnDelete = (task) => {
    alert(1)
    if (window.confirm('Comfimer ?')) {
      onUpdate(tasks.filter((t) => t.tskId !== task.tskId));
    }
  }
  return (
    <div className="tasks-list">

      <Row className="tasks-list-header tasks-list-row ">
        <Col className="select"></Col>
        <Col className="categories">Catégories</Col>
        {false && <Col className="title">Titre</Col>}
        <Col className="text">Description</Col>
        <Col className="todo-on">Echéance</Col>
        <Col className="documents">Doc.</Col>
        <Col className="state">Etat</Col>
        <Col className="func"></Col>
      </Row>
      <div className="tasks-list-body">
        {tasks.map((task) => (
          <TasksListItem key={task.tskId} task={task} onUpdate={handleTaskUpdate} onSelect={handleOnSelect} onDelete={handleOnDelete} />
        ))}
      </div>
      {false && <pre>{JSON.stringify(tasks[0], null, 2)}</pre>}

    </div >
  );
}

export default Tasks;
