import { useEffect, useState } from 'react';
import API from '../../api';
import TreeTasks from './TreeTasksList';
import TreeTask from './TreeTask';
import { useFormContext } from 'react-hook-form';
import { BsPlusSquare, BsChevronLeft, BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { CustomButtonsGroup, CustomPreviousBtn, CustomNextBtn, CustomBackBtn } from '../form-controls/CustomButtons';
const TreeFormTasks = ({ parentId }) => {
  const { getValues, setValue } = useFormContext()
  const [viewMode, setViewMode] = useState('list');
  const [selectedTask, setSelectedTask] = useState(null);
  useEffect(() => {
    if (window.localStorage.getItem('treesTasksViewMode')) {
      setViewMode(window.localStorage.getItem('treesTasksViewMode'));
    }
  }, []);
  const handleTasksUpdate = (new_tasks) => {
    setValue('tasks', new_tasks)
  }
  const handleCreateTask = async () => {
    const response = await API.post('/task', { tskParentId: getValues('itId'), tskStatus: 'creating' })
    console.log(response)
    const created_task = { ...response.data };
    setValue('tasks', [...getValues('tasks'), created_task])
    setSelectedTask(created_task);
  }
  const handleTaskUpdate = (new_task) => {
    console.log(new_task.tskText)

    const updatedTasks = getValues('tasks').map((task) => task.tskId === new_task.tskId ? new_task : task);
    setValue('tasks', updatedTasks);
    setSelectedTask(new_task)
  }
  const handleViewModeChange = (vm) => {
    setViewMode(vm)
    window.localStorage.setItem('treesTasksViewMode', vm);
  }
  const handleTaskSelect = (task) => {
    setSelectedTask(task);
  }
  const getSelectedTaskIndex = () => {
    const tasks = getValues('tasks');
    let index = null;
    if (selectedTask) {
      index = tasks.findIndex(t => t.tskId == selectedTask.tskId);
    }
    return index
  }
  const handleTasksNav = (sens) => {
    const tasks = getValues('tasks');
    const nextIndex = (getSelectedTaskIndex() + sens + tasks.length) % tasks.length;// ChatGPT 
    setSelectedTask({ ...tasks[nextIndex] });
  };
  return (
    <div className='tree-tasks' >
      <div className='d-flex justify-content-between  mb-4'>
        <div className="tasks-navbar">
          {selectedTask != null && (
            <CustomButtonsGroup>
              <CustomBackBtn onClick={() => setSelectedTask(null)}>Retour à la liste des taches</CustomBackBtn>
            </CustomButtonsGroup>)}
        </div>
        <div className='text-end'>
          <CustomButtonsGroup>

            {selectedTask === null &&
              <span className="pointer" onClick={handleCreateTask}><BsPlusSquare size="30" /></span>
            }
            {selectedTask != null &&
              <>
                <CustomPreviousBtn onClick={() => handleTasksNav(-1)} />
                <span className='d-inline-block mx-3'>
                  Tâche {getSelectedTaskIndex() + 1} / {getValues('tasks').length}
                </span>
                <CustomNextBtn onClick={() => handleTasksNav(+1)} />
              </>
            }

            {/* CustomButtonsGroup, CustomPreviousBtn, CustomNextBtn */}
          </CustomButtonsGroup>
        </div>
      </div>
      {selectedTask == null &&
        <TreeTasks
          tasks={getValues('tasks')}
          viewMode={viewMode}
          onViewModeChange={handleViewModeChange}
          onUpdate={handleTasksUpdate}
          onSelect={handleTaskSelect}
        />
      }
      {selectedTask != null &&
        <div>
          <TreeTask
            task={selectedTask}
            onUpdate={handleTaskUpdate}
          />
        </div>
      }
      {false && <pre>{JSON.stringify(getValues('tasks'), null, 2)}</pre>}
    </div >
  );
};
export default TreeFormTasks
