import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FilesList from '../files/FilesList';
const TreeFormPictures = ({ parentId }) => {
  const { getValues, setValue } = useFormContext()
  const [viewMode, setViewMode] = useState('grid');
  useEffect(() => {
    if (window.localStorage.getItem('treesPicturesViewMode')) {
      setViewMode(window.localStorage.getItem('treesPicturesViewMode'));
    }
  }, []);
  const handleViewModeChange = (vm) => {
    setViewMode(vm)
    window.localStorage.setItem('treesPicturesViewMode', vm);
  }
  const onUploadStart = (file) => {
  };
  const onUploadSuccess = (file) => {
  };
  const onUploadFailure = (file) => {
  };
  const handlePicturesChange = (new_pictures) => {
    setValue('pictures', new_pictures)
  }
  return (
    <div className='tree-pictures'>
      <FilesList
        files={getValues('pictures')}
        className="pictures"
        viewMode={viewMode}
        onViewModeChange={handleViewModeChange}
        uploaderInputProps={{
          accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
          },
          multiple: true,
        }}
        onChange={handlePicturesChange}
        onUploadStart={onUploadStart}
        onUploadSuccess={onUploadSuccess}
        onUploadFailure={onUploadFailure}
      />
    </div>
  );
};
export default TreeFormPictures;

/*





*/