// src/components/homes/HomeDefault.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showModal, hideModal } from '../../redux/modalSlice';
import './homes.css';
const HomeMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleModalOpen = () => {
    dispatch(showModal()); // Dispatche l'action "show"
  }
  const handleNavigateTo = (url) => {
    navigate(url);
  };
  return (
    <div className="home home-main">
      <div className='container'>
        <h1 className='text-center'>IF HOME MAIN</h1>
        <ul className='pictos'>
          <li onClick={() => handleNavigateTo('/map')}>
            <i className="bi bi-pin-map"></i>
            Carte
          </li>
        </ul>
      </div>

    </div>
  );
};
export default HomeMain;
