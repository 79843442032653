// CustomMarker.js
import React from 'react';
import { Marker } from '@react-google-maps/api';

const CustomMarker = ({ position, unlocked = false, onClick, onDragEnd, color, clusterer }) => {
  return (
    <Marker
      position={position}
      draggable={unlocked}
      icon={{
        url: 'https://static.if.an1.fr/img' + (unlocked ? "/marker-green.png" : "/marker-green-lk.png"),
        scaledSize: new window.google.maps.Size(24, 28)
      }}
      onClick={onClick}
      onDragEnd={onDragEnd}
      clusterer={clusterer}
    />
  );
};

export default CustomMarker;


/*
icon={"https://maps.google.com/mapfiles/ms/icons/" + color + "-dot.png"}
*/