import React, { useEffect, useState, useRef } from 'react';
import { FiCheck } from 'react-icons/fi'; // Remplace avec l'icône de validation souhaitée
import './fileEditWordings.css';
const FileEditWordings = ({ file, onUpdate, onClose, labels = false }) => {
  const [editedFileName, setEditedFileName] = useState(file.fileName);
  const [editedFileComment, setEditedFileComment] = useState(file.fileComment);
  const [isDirty, setIsDirty] = useState(false);
  const handleSaveClick = () => {
    onUpdate(editedFileName, editedFileComment);
    setIsDirty(false);
    onClose();
  };
  const handleCancelClick = () => {
    setEditedFileName(file.fileName);
    setEditedFileComment(file.fileComment);
    onClose();
  };
  const focusNextElement = (currentElement) => {
    const formElements = fileEditWordingsRef.current.querySelectorAll('input, textarea');
    const currentIndex = Array.from(formElements).indexOf(currentElement);
    const nextIndex = (currentIndex + 1) % formElements.length;
    const nextElement = formElements[nextIndex];
    nextElement.focus();
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 9) { // Vérifie si la touche appuyée est la touche Tab (code 9)
      event.preventDefault(); // Empêche le comportement par défaut de la touche Tab
      focusNextElement(event.target); // Passe au champ suivant
    }
  };
  const handleOnChange = (e) => {
    setIsDirty(true);
    switch (e.target.name) {
      case 'fileName':
        setEditedFileName(e.target.value)
        break;
      case 'fileComment':
        setEditedFileComment(e.target.value);
        break;
    }
  }
  const handleClickOutside = (event) => {
    if (!fileEditWordingsRef.current.contains(event.target)) {
      if (isDirty && window.confirm("Les modification seront perdues")) {
        onClose();
      }
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const fileEditWordingsRef = useRef();
  return (
    <div className='file-edit-wording' ref={fileEditWordingsRef} >
      {labels && <label className='mb-1'>Nom du fichier</label>}
      <input
        className='mb-2'
        name="fileName"
        type="text"
        value={editedFileName}
        onChange={handleOnChange}
      />
      {labels && <label className='mb-1'>Description</label>}
      <textarea
        name="fileComment"
        value={editedFileComment}
        onChange={handleOnChange}
        placeholder='description, commentaire, note...'
      />
      <div className="edit-icons">
        <span onClick={handleSaveClick}>
          <FiCheck /> {/* Remplace IconSave avec l'icône de validation souhaitée */}
        </span>
        <span type="button" onClick={handleCancelClick}>
          X
        </span>
      </div>
    </div>
  );
};
export default FileEditWordings;
