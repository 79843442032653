import api from './../api';
const UserService = {

  isLogged: () => {
    const loggedIn = window.localStorage.getItem('token') ? true : false;
    return loggedIn;
  },
  signIn: async (data) => {
    // try {
    const response = await api.post('/login/signIn', data);

    if (response.data && typeof response.data.token != 'undefined') {
      window.localStorage.setItem('token', response.data.token);
      window.localStorage.setItem('roles', JSON.stringify(response.data.roles));
      return true;
    }
    // } catch (error) {
    //   throw new Error(error.response.data.error)
    //  return false;
    // }


  },


  signOut: () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('roles');
    window.localStorage.removeItem('user');
  },
  getToken: () => {
    return window.localStorage.getItem('token') ? window.localStorage.getItem('token') : false;
  },

};

export default UserService;