import React, { useState, useEffect, useRef } from 'react';
import { BiCircle, BiUndo, BiRedo, BiTrash, BiSave } from "react-icons/bi";
import { PiRectangle, PiLineSegmentDuotone, PiTextTLight, PiPencilLight } from "react-icons/pi";
import { CgArrowTopLeft } from "react-icons/cg";
const DrawingLayerToolbar = ({ onClick, currentTool, currentColor }) => {
  const [toolbarPosition, setToolbarPosition] = useState({ x: 150, y: 75 });
  const [cropImage, setCropImage] = useState(false);
  // const [currentTool, setCurrentTool] = useState("");
  const isDraggingRef = useRef(false);
  const [currentThickness, setCurrentThickness] = useState("m");
  useEffect(() => {
    const storedPosition = JSON.parse(localStorage.getItem('toolbarPosition'));
    if (storedPosition) {
      storedPosition.x = Math.min(storedPosition.x, window.innerWidth - 200)
      storedPosition.y = Math.min(storedPosition.x, window.innerHeight - 200)
      setToolbarPosition(storedPosition);
    }
    return () => {
    };
  }, []);
  const handleClick = (tool, arg1 = null) => {
    console.log(tool, arg1)
    onClick(tool, arg1)
    if (tool == 'thickness') {
      setCurrentThickness(arg1)
    }
  };
  const handleMouseDown = (e) => {
    isDraggingRef.current = true;
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };
  const handleMouseMove = (e) => {
    if (isDraggingRef.current) {
      try {
        const newX = e.clientX + 20;// - e.target.offsetWidth / 2;
        const newY = e.clientY + 10;// - e.target.offsetHeight / 2;
        setToolbarPosition({ x: newX, y: newY });
      } catch (e) {
      }
    }
  };
  const handleMouseUp = (e) => {
    isDraggingRef.current = false;
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };
  return (
    <div
      className={`drawing-layer-toolbar ${isDraggingRef.current ? 'is-dragging' : ''}`}
      style={{ position: 'fixed', left: toolbarPosition.x, top: toolbarPosition.y }}
    >
      <div onMouseDown={handleMouseDown} className="drag-handler">
      </div>
      <div>
        <button onClick={() => handleClick('select')} className={currentTool === 'select' ? 'jade' : ''}>
          <CgArrowTopLeft />
        </button>
        <button onClick={() => handleClick('circle')} className={currentTool === 'circle' ? 'jade' : ''}>
          <BiCircle />
        </button>
        <button onClick={() => handleClick('rectangle')} className={currentTool === 'rectangle' ? 'jade' : ''}>
          <PiRectangle />
        </button>
        <button onClick={() => handleClick('line')} className={currentTool === 'line' ? 'jade' : ''}>
          <PiLineSegmentDuotone />
        </button>
        <button onClick={() => handleClick('text')} className={currentTool === 'text' ? 'jade' : ''}>
          <PiTextTLight />
        </button>
        <button onClick={() => handleClick('pencil')} className={currentTool === 'pencil' ? 'jade' : ''}>
          <PiPencilLight />
        </button>
      </div>
      <div>
        <button onClick={() => handleClick('undo')}>
          <BiUndo />
        </button>
        <button onClick={() => handleClick('redo')}>
          <BiRedo />
        </button>
        <button onClick={() => handleClick('delete')}>
          <BiTrash />
        </button>
      </div>
      <div>
        <button onClick={() => handleClick('thickness', 3)} disabled={cropImage} className={currentThickness === 3 ? 'jade' : ''}>
          <span className="thickness thickness-1"></span>
        </button>
        <button onClick={() => handleClick('thickness', 5)} disabled={cropImage} className={currentThickness === 5 ? 'jade' : ''}>
          <span className="thickness thickness-2"></span>
        </button>
        <button onClick={() => handleClick('thickness', 8)} disabled={cropImage} className={currentThickness === 8 ? 'jade' : ''}>
          <span className="thickness thickness-3"></span>
        </button>
      </div>
      <div>
        <input
          disabled={cropImage}
          type="color"
          value={currentColor}
          onChange={(e) => handleClick('color', e.target.value)}
        /></div>
      <div>
        <button onClick={() => handleClick('save')} disabled={cropImage} className={currentThickness === 5 ? 'jade' : ''} className="btn-save"><BiSave size="24" /></button>
      </div>
    </div>
  );
};
export default DrawingLayerToolbar;
