import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './tasks.css';
import MegaDropdown from "../form-controls/MegaDropdown";
import CustomRange from "./../form-controls/CustomRange";
import ReactDatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale, } from "react-datepicker";
import datepicker_fr from 'date-fns/locale/fr';
import moment from 'moment'
import useOnClickOutside from 'use-onclickoutside'
import { FiEdit2, FiCalendar } from "react-icons/fi";
import { IoClose } from 'react-icons/io5';
registerLocale('fr', datepicker_fr);
/** 
 * -------------------------------
 *  CATEGORIES
 *  ------------------------------- 
 */
export const TaskCategories = ({ sourceItems, selection, inputType = 'checkbox', editable, height, onChange }) => {
  const [title, setTitle] = useState('');
  useEffect(() => {
    setTitle("> Sélectionner");
    if (typeof selection !== 'object') {
      const category = sourceItems.find(cat => cat.id === selection[0]);
      if (category) {
        setTitle(category.text);
      }
    }
  }, [selection]);
  return (
    <div className="task-categories">
      <MegaDropdown
        title={title}
        sourceItems={sourceItems}
        selection={selection}
        onUpdate={selection => onChange(selection)}
        iconSize={22}
        inputType={inputType}
      />
      {inputType == 'checkbox' &&
        <ul className="currents text-start list-unstyled p-0 m-0 pt-3">
          {selection.map(categoryId => {
            const category = sourceItems.find(cat => cat.id === categoryId);
            if (category) {
              return <li className="small" key={category.id}>{category.text}</li>;
            }
            return null;
          })}
        </ul>
      }
    </div>
  )
};
/** 
 * -------------------------------
 *  DESCRIPTION 
 *  ------------------------------- 
 */
export const TaskDescription = ({ value, inputClassName, title = '', editable, readOnly = false, height, onChange }) => {
  const [elementReadOndly, setElementReadOndly] = useState(readOnly)
  const elementRef = useRef(null)
  useEffect(() => {
    if (elementReadOndly == false) {
      elementRef.current.focus();
      const length = elementRef.current.value.length;
      elementRef.current.setSelectionRange(length, length);
    }
  }, [elementReadOndly])
  return (
    <div className="task-description">
      <div className="d-flex">
        <textarea
          value={value}
          className={`flex-grow ${inputClassName}`}
          editable={editable}
          readOnly={elementReadOndly}
          onChange={onChange}
          ref={elementRef}
        />
        {editable == true &&
          <div className="ps-2 d-flex justify-content-between tasks-list-todo-on">
            {elementReadOndly &&
              <span className="d-inline-block">
                <FiEdit2 size="16" onClick={() => setElementReadOndly(!elementReadOndly)} className="pointer" />
              </span>
            }
            {!elementReadOndly &&
              <span className="d-inline-block">
                <IoClose size="16" onClick={() => setElementReadOndly(!elementReadOndly)} className="pointer" />
              </span>}
          </div>
        }
      </div>
    </div>
  );
};
/** 
 * -------------------------------
 *  ECHEANCE + DATE D'ALERTE
 *  ------------------------------- 
 */
export const TaskDueDate = ({ dueDate, alertOn, allowAlert = false, alertActive = false, onChange }) => {
  const [showDatepickers, setShowDatepickers] = useState({ tskDueDate: false, tskAlertOn: false });
  const [message, setMessage] = useState('');
  const toggleDatepickers = (key) => {
    setShowDatepickers({ ...showDatepickers, [key]: !showDatepickers[key] });
  }
  const handleDateChange = (key, date) => {
    toggleDatepickers(key);
    onChange({ [key]: date });
  }
  useEffect(() => {
    let message = '';
    if (dueDate && alertOn) {
      if (new Date(dueDate).getTime() < new Date(alertOn).getTime()) {
        message = "La date d'alerte est postérieure à la date d'échance";
      }
    }
    setMessage(message);
  }, [dueDate, alertOn]);
  const dpDueDate = useRef(null);
  useOnClickOutside(dpDueDate, () => {
    setShowDatepickers({ ...showDatepickers, tskDueDate: false });
  });
  const dpAlertOn = useRef(null);
  useOnClickOutside(dpAlertOn, () => {
    setShowDatepickers({ ...showDatepickers, tskAlertOn: false });
  });
  return (
    <div className="task-due-date">
      <div className="mb-4">
        <span onClick={() => toggleDatepickers('tskDueDate')} className="pointer" >
          {dueDate ? moment(dueDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : 'date non précisée'}
          <span className="px-2"><FiCalendar size="16" style={{ position: 'relative', top: '-2px' }} /></span>
        </span>
        <span ref={dpDueDate}>
          {showDatepickers.tskDueDate == true && <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            locale="fr"
            inline
            showWeekNumbers
            closeOnScroll={true}
            selected={dueDate ? new Date(dueDate) : null}
            onChange={(date) => handleDateChange('tskDueDate', date)}>
            <div style={{ color: "red", display: 'none' }}>Don't forget to check the weather ;-)</div>
          </ReactDatePicker>
          }</span>
      </div>
      {allowAlert &&
        <>
          <div className="mb-2">
            <label >
              <input type="checkbox" onChange={e => onChange({ tskAlertActive: e.target.checked })} checked={alertActive} className="me-1" />
              Activer l'alerte
            </label>
          </div>
          {
            alertActive && <div>
              <div className="mb-4">
                <span onClick={() => toggleDatepickers('tskAlertOn')} className="pointer" >
                  {alertOn ? moment(alertOn, 'YYYY-MM-DD').format('DD-MM-YYYY') : 'date non précisée'}
                  <span className="px-2"><FiCalendar size="16" style={{ position: 'relative', top: '-2px' }} /></span>
                </span>
                <span ref={dpAlertOn}>
                  {showDatepickers.tskAlertOn == true && <ReactDatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="fr"
                    inline
                    showWeekNumbers
                    closeOnScroll={true}
                    selected={alertOn ? new Date(alertOn) : null}
                    onChange={(date) => handleDateChange('tskAlertOn', date)}>
                    <div style={{ color: "red", display: 'none' }}>Don't forget to check the weather ;-)</div>
                  </ReactDatePicker>
                  }</span>
              </div>
              {message != '' &&
                <div className="alert alert-warning alert-dismissible fade show p-1">
                  <small>{message}</small>
                </div>
              }
            </div>
          }
        </ >
      }
    </div >
  );
};
/** 
 * -------------------------------
 *  STATE + PROGRESS
 *  ------------------------------- 
 */
export const TaskState = ({ state, progress, onChange }) => {
  const tasksStates = useSelector(state => state.config.tasksStates);
  const [taskState, setTaskState] = useState({}); // Initialisez à null ou une valeur par défaut appropriée
  useEffect(() => {
    const stateObj = tasksStates.find(s => s.id === state);
    if (stateObj) {
      setTaskState(tasksStates.find(s => s.id === state));
    }
    return
  }, [state]);
  const handleOnStateUpdate = (state) => {
    console.log(state)
    const obj = { tskState: state };
    if (state == 'todo') {
      obj.tskProgress = 0;
    }
    if (state == 'inProgress' && (progress == 0 || progress == 100)) {
      obj.tskProgress = 50;
    }
    if (state == 'completed') {
      obj.tskProgress = 100;
    }
    console.log(obj)
    onChange(obj);
  }
  const handleOnProgressUpdate = (value) => {
    const obj = { tskProgress: value }
    if (value == 0 && value < 100 && ['inProgress', 'completed'].indexOf(state) > -1) {
      obj.tskState = 'todo';
    }
    if (value > 0 && value < 100 && state == 'todo') {
      obj.tskState = 'inProgress';
    }
    if (value == 100 && ['todo', 'inProgress'].indexOf(state) > -1) {
      obj.tskState = 'completed';
    }
    onChange(obj);
  }
  return (
    <div>
      <div className={`d-flex justify-content-between align-items-center p-1 mb-4 task-${state}`}>
        <span className="round-pin me-2 position-relative" style={{ backgroundColor: taskState.color, top: '-2px' }}></span>
        <div className="flex-grow-1">
          <MegaDropdown
            sourceItems={tasksStates}
            title={taskState ? taskState.text : ''}
            selection={state}
            iconItemKey='icon' // clef icon des objects de tasksStates
            pinColorItemKey='color' // clef icon color objects de tasksStates
            onUpdate={handleOnStateUpdate}
            iconSize={22}
            inputType="radio"
          /></div>
      </div>
      <div className="px-1 mt-2 pe-3">
        Progression : {progress}%
        <CustomRange
          type="range"
          className="form-range"
          min={0}
          max={100}
          value={progress}
          onChange={handleOnProgressUpdate}
          thumbColor="white"
          thumbBackground="#666"
        />
      </div>
    </div>
  )
}