// https://codesandbox.io/s/fabric-js-example-8ntuqt?file=/src/App.js:0-8504
import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import API from './../../../api';
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";

import DrawingLayerToolbar from "./DrawingLayerToolbar";
import * as actions from "./DrawingLayerActions";
import './drawingLayer.css';
export default function Drawing({ width, height, scale = 1, parentId, onUpdate }) {
  const { editor, onReady } = useFabricJSEditor();
  const history = [];
  const [initialized, setInitialized] = useState(false);
  const [cropImage, setCropImage] = useState(false);
  const [currentTool, setCurrentTool] = useState('select');
  const [currentThickness, setCurrentThickness] = useState("5");
  const [currentColor, setCurrentColor] = useState('#ff0000');
  const [copiedObjects, setCopiedObjects] = useState([]);
  // const [canvasContents, setCanvasContents] = useState('');
  const [canvasFile, setCanvasFile] = useState(null);
  /*
  useEffect(() => {
    console.clear()
    console.log('--- parentId changes')
    setInitialized(false);
    setCanvasFile(null);
    API.get('/files', { // recupération du ficher canvas associé au parentId
      params: {
        query: {
          'fileParentId:eq': parentId,
          'fileCategory:eq': 'canvas'
        },
        limit: 1,
        contents: true,
      }
    }).then(response => {
      if (response.data.length == 1) {
        response.data[0].contents = window.atob(response.data[0].contents);
        console.log('canvas contents loaded')
        setCanvasFile(response.data[0]);
        window.setTimeout(() => {
          console.log(editor)
          //editor.canvas.loadFromJSON(response.data[0].contents, editor.canvas.renderAll.bind(editor.canvas));
        }, 3000)


      }
    });
    return () => {
      console.log('ICI');
      // editor.canvas.dispose();
    };
  }, [parentId]);
  */

  // INSTANCIATION EDITOR
  useEffect(() => {
    console.log('*** editor changes')
    if (!editor || !fabric || editor.initialized == true) {
      return;
    }
    if (cropImage) {
      editor.canvas.__eventListeners = {};
      return;
    }
    /* CANVAS MOUSE DOWN */
    if (!editor.canvas.__eventListeners["mouse:down"]) {
      editor.canvas.on("mouse:down", function (opt) {
        if (this.isDragging) {
          return false;
        }
        if (opt.target == null && this.currentTool != 'select') {
          this.drawingObject = {
            positionned: false,
          }
          // editor.canvas.renderAll();
        }
        return
      });
    }
    if (!editor.canvas.__eventListeners["mouse:move"]) {
      editor.canvas.on("mouse:move", function (opt) {
        if (this.drawingObject) {
          this.selection = false;
          const distance = {
            x: Math.abs(opt.pointer.x - this.drawingObject.left),
            y: Math.abs(opt.pointer.y - this.drawingObject.top)
          }
          if (this.drawingObject.positionned == false) {
            switch (this.currentTool) {
              case 'circle':
                this.drawingObject = actions.addCircle(editor, {
                  left: opt.pointer.x,
                  top: opt.pointer.y,
                });
                break;
              case 'rectangle':
                this.drawingObject = actions.addRect(editor, {
                  left: opt.pointer.x,
                  top: opt.pointer.y,
                });
                break;
              case 'line':
                this.drawingObject = actions.addLine(editor, {
                  left: opt.pointer.x,
                  top: opt.pointer.y,
                });
                break;
              case 'text':
                this.drawingObject = actions.addText(editor, {
                  left: opt.pointer.x,
                  top: opt.pointer.y,
                });
                break;
            }
          } else {
            switch (this.currentTool) {
              case 'circle':
                this.drawingObject.set('radius', distance.x);
                editor.canvas.requestRenderAll();
                break;
              case 'rectangle':
                this.drawingObject.set('width', distance.x);
                this.drawingObject.set('height', distance.y);
                editor.canvas.requestRenderAll();
                break;
              case 'line':
                this.drawingObject.set('x2', opt.pointer.x);
                this.drawingObject.set('y2', opt.pointer.y);
                editor.canvas.requestRenderAll();
                break;
              case 'text':
                this.drawingObject.set('fontSize', Math.max(10, distance.x / 5));
                editor.canvas.requestRenderAll();
                break;
            }
          }
          return;
        }
        if (this.isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });
    }
    if (!editor.canvas.__eventListeners["mouse:up"]) {
      editor.canvas.on("mouse:up", function (opt) {
        this.drawingObject = null;
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
        this.selection = true;
      });
    }
    editor.canvas.setDimensions(width, height);
    editor.canvas.renderAll();
    if (initialized === false) {
      setEditorParams();
      getCanvasFile();
      /*
      if (canvasFile) {
        console.log('Passe ici')
        editor.canvas.loadFromJSON(canvasFile.contents, editor.canvas.renderAll.bind(editor.canvas));

      }
      */
      setInitialized(true)
    }

    const handleKeyDown = (e) => {
      // console.log('touche enfoncée:', e, ' tagName', e.target.tagName);
      if (e.target.tagName.toLowerCase() == 'textarea') {
        return
      }
      if (e.keyCode == 27) {
        setCurrentTool('select');
      }
      if (e.keyCode == 67) {
        setCurrentTool('circle');
      }
      if (e.keyCode == 67) {
        setCurrentTool('circle');
      }
      if (e.keyCode == 46) {
        actions.deleteSelected(editor);
      }
      if (e.keyCode == 67 && e.ctrlKey == true) {
        const activeObjects = editor.canvas.getActiveObjects();
        if (activeObjects.length > 0) {
          setCopiedObjects(activeObjects.map(obj => fabric.util.object.clone(obj)));
        }
        return;
      }
      if (e.keyCode == 86 && e.ctrlKey == true) { // TODO a porter dans actions.js
        if (copiedObjects.length > 0) {
          editor.canvas.discardActiveObject();
          const newCopiedObjects = [];
          copiedObjects.forEach(copiedObject => {
            const clonedObject = fabric.util.object.clone(copiedObject);
            clonedObject.set({ left: clonedObject.left + 10, top: clonedObject.top + 10 });
            editor.canvas.add(clonedObject);
            editor.canvas.setActiveObject(clonedObject);
            newCopiedObjects.push(clonedObject);
          });
          setCopiedObjects(newCopiedObjects);
          editor.canvas.requestRenderAll();
        }
        return;
      }
      if (e.keyCode == 90 && e.ctrlKey == true) {
        undo();
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [editor]);
  const getCanvasFile = () => {
    API.get('/files', { // recupération du ficher canvas associé au parentId
      params: {
        query: {
          'fileParentId:eq': parentId,
          'fileCategory:eq': 'canvas'
        },
        limit: 1,
        contents: true,
      }
    }).then(response => {
      if (response.data.length == 1) {
        response.data[0].contents = window.atob(response.data[0].contents);
        console.log('canvas contents loaded')
        setCanvasFile(response.data[0]);
        editor.canvas.loadFromJSON(response.data[0].contents, editor.canvas.renderAll.bind(editor.canvas));
      }
    });
  }
  const handleSave = () => {
    if (canvasFile === null) {
      API.post('/file', {
        content: JSON.stringify(editor.canvas),
        fileName: parentId + '.json',
        fileCategory: 'canvas',
        fileParentId: parentId,
      }).then(response => {
        setCanvasFile(response.data)
      })
    } else {
      API.put('/file/' + canvasFile.fileId, {
        contents: JSON.stringify(editor.canvas),
      }).then(response => {
        setCanvasFile(response.data)
      })

    }
  }
  /***  -----------------------------------------    */
  useEffect(() => {
    setEditorParams();
  }, [currentTool, currentThickness, currentColor])
  /***  -----------------------------------------    */
  useEffect(() => {
    if (editor) {
      // console.clear();
      editor.canvas.getActiveObjects().forEach((obj) => {
        obj.set('strokeWidth', currentThickness);
      });
      editor.canvas.requestRenderAll();
    }
  }, [currentThickness]);
  /***  -----------------------------------------    */
  useEffect(() => {
    actions.updateSelected(editor, 'strokeWidth', currentThickness)
  }, [currentThickness]);
  /***  -----------------------------------------    */
  useEffect(() => {
    actions.updateSelected(editor, 'stroke', currentColor)
  }, [currentColor]);
  /***  -----------------------------------------    */
  const undo = () => {
    if (editor.canvas._objects.length > 0) {
      history.push(editor.canvas._objects.pop());
    }
    editor.canvas.renderAll();
  }
  const setEditorParams = () => {
    if (editor) {
      editor.canvas.currentTool = currentTool;
      editor.canvas.freeDrawingBrush.color = currentColor;
      editor.canvas.freeDrawingBrush.width = parseInt(currentThickness)
      editor.setStrokeColor(currentColor);
      editor.strokeColor = currentColor
    }
  }
  /* RETOUR DE LA TOOLBAR */
  const handleToolbarClick = (tool, arg1 = null) => {
    editor.canvas.isDrawingMode = tool == 'pencil';
    switch (tool) {
      case 'select':
      case 'circle':
      case 'rectangle':
      case 'line':
      case 'pencil':
      case 'text':
        setCurrentTool(tool);
        break;
      case 'thickness':
        setCurrentThickness(arg1)
        break;
      case 'delete':
        actions.deleteSelected(editor);
        break;
      case 'undo':
        undo()
        break;
      case 'redo':
        if (history.length > 0) {
          editor.canvas.add(history.pop());
        }
        break;
      case 'color':
        setCurrentColor(arg1);
        break;
      case 'save':
        handleSave();
        break;
    }
  }
  const handleObjectSelected = () => {
    console.log('jade ?')
  }
  return (
    <div className="drawing-layer" style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: width,
      height: height,
      zIndex: 1,
    }}
    >
      <DrawingLayerToolbar
        onClick={handleToolbarClick}
        currentTool={currentTool}
        currentColor={currentColor}
        onObjectSelected={handleObjectSelected}
      />
      <FabricJSCanvas className="sample-canvas" onReady={onReady} style={{ height }} />
      {false && <p>{currentTool} &bull; {currentColor} / #{editor?.selection}### {width}X{height} / {canvasFile?.contents.length}</p>}
    </div >
  );
}
