import React, { useState } from 'react';
// import './markers.css';



import { PiTreeBold, PiLineSegmentBold } from "react-icons/pi";





const MapContextMenu = ({ top, left, onClick }) => {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const handleClick = (action, args = null) => {
    onClick(action, args);
  };
  return (
    <div
      id="marker-context-menu"
      style={{ top, left }}
      className="context-menu"
    > <div className="text-end">
        <span className='close pointer' onClick={() => handleClick('close')}>&times;</span> </div>
      <ul>
        <li onClick={() => handleClick('createTree')} className='pointer'><PiTreeBold size="22" color="#333" className="pe-1" /> Ajouter un arbre</li>
        <li onClick={() => handleClick('createAlignment')} className='pointer'><PiLineSegmentBold size="22" color="#333" className="pe-1" /> Créer un alignement</li>

        {confirmDelete === false && false &&
          <li onClick={() => setConfirmDelete(true)} className='pointer'><i className="bi bi-trash"></i> Supprimer</li>
        }
        {confirmDelete === true && false &&
          <li className='pointer text-end'>
            <span className='btn btn-xs btn-danger' onClick={() => handleClick('delete')} >OK</span>
            <span className='btn btn-xs btn-secondary' onClick={() => setConfirmDelete(false)}>Annuler</span>
          </li>
        }
      </ul>


    </div >
  );
};

export default MapContextMenu;

