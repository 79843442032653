// configSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from './../api';
// Action asynchrone pour charger les données depuis l'API
export const getConfig = createAsyncThunk('config/getConfig', async () => {
  const response = await api.get('/config');
  return response.data;
});
const configSlice = createSlice({
  name: 'config',
  initialState: {
    trees_task_categories: [], // Nouvelle variable pour stocker les catégories de tâches d'arbres
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getConfig.fulfilled, (state, action) => {
      const config = JSON.parse(atob(action.payload));
      for (const k in config) {
        state[k] = config[k];
      }
    });
  },
});
export default configSlice.reducer;
