import { PiLeafFill, PiLeafThin } from 'react-icons/pi';

const LeafCheckbox = ({
  iconSize = 18,
  iconColor = '#333',
  className = '',
  name = '',
  value = '',
  style = {},
  checked = false,
  icon = '',
  pinColor = '',
  type = 'checkbox',
  onChange }) => {
  const spanStyle = {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    marginRight: '5px',
    ...style,
  };
  return (
    <span style={spanStyle} className={`leaf-checkbox ${className}`}>
      <input
        type={type}
        checked={checked}
        onChange={onChange}
        className="mr-1"
        name={name}
        value={value}
        style={{ display: 'none' }}
      />
      {icon == '' && pinColor == '' &&
        (<i className='check' style={{ color: iconColor }}>
          {checked && <PiLeafFill size={iconSize} />}
          {!checked && <PiLeafThin size={iconSize} style={{ opacity: '0.5' }} />}
        </i>)
      }
      {icon != '' &&
        (<span>
          <img src={icon} height={30} />
        </span>)
      }
      {pinColor != '' &&
        (<span className='round-pin' style={{ backgroundColor: pinColor }}  >
        </span>)
      }
    </span>
  );
};
export default LeafCheckbox; 