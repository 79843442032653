import { createSlice } from '@reduxjs/toolkit';
const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modalVisible: false,
  },
  reducers: {
    showModal: (state) => {
      state.modalVisible = true;
    },
    hideModal: (state) => {
      state.modalVisible = false;
    },
  },
});
export const { showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;