import React, { useRef } from 'react';
const TouchContainer = ({ children, onSwipeLeft, onSwipeRight, className = '' }) => {
  const touchStartRef = useRef(null);
  const handleTouchStart = (event) => {
    touchStartRef.current = event.touches[0].clientX;
  };
  const handleTouchMove = (event) => {
    const touchEnd = event.touches[0].clientX;
    const delta = touchEnd - touchStartRef.current;
    if (delta > 50) {
      // Swipe right
      onSwipeRight();
    } else if (delta < -50) {
      // Swipe left
      onSwipeLeft();
    }
  };
  const handleTouchEnd = () => {
    touchStartRef.current = null;
  };
  return (
    <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} className={className}>
      {children}
    </div>
  );
};

export default TouchContainer;
