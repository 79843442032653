// AuthGuard.js

import React, { useState, useEffect } from 'react';
import UserService from "./services/UserService";
import { useLocation, Navigate } from 'react-router-dom';
const AuthGuard = ({ children }) => {
  const location = useLocation();

  const isAuthenticated = UserService.isLogged();
  if (!isAuthenticated) {
    return <Navigate to={"/login?path=" + location.pathname} replace />;
  }
  return <>{children}</>;
};
export default AuthGuard;