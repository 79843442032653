import React, { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CustomButtonsGroup, CustomDeleteBtn, CustomNewWindowBtn } from "../form-controls/CustomButtons";
import { TaskCategories, TaskDescription, TaskDueDate, TaskState } from './../tasks/TasksComponents';
import LeafCheckbox from "../form-controls/LeafCheckbox";
import './../tasks/tasks.css';

/**  */
const TasksListItem = ({ task, onUpdate, onSelect, onDelete }) => {
  const treesTasksCategories = useSelector(state => state.config.treesTasksCategories);
  const updateTask = async (params) => {
    console.log('updateTask', params);
    onUpdate({ ...task, ...params });
  }
  /** task checked */
  const handleOnSelect = (e) => {
    console.log(e)
    const updated = updateTask({ tskChecked: e.target.checked });
  }
  useEffect(() => {
  }, [task]);
  return (
    <Row className={`tasks-list-item tasks-list-row ${task.tskChecked ? 'selected' : ''}`}>
      <Col className="text-center select">
        <label>
          <LeafCheckbox
            checked={task.tskChecked}
            onChange={handleOnSelect}
            className='mr-1'
            iconSize={20}
          />
        </label>
      </Col>
      <Col className="text-center categories">
        <TaskCategories
          sourceItems={treesTasksCategories}
          selection={task.tskCategories}
          inputType='checkbox'
          onChange={(selection) => updateTask({ tskCategories: selection })}
        />
      </Col>
      <Col className="">
        <TaskDescription
          value={task.tskText}
          inputClassName="tskText form-control form-control-sm"
          onChange={(e) => updateTask({ tskText: e.target.value })}
          readOnly={true}
          editable={true}
        />
      </Col>
      <Col className="text-center todo-on" >
        <TaskDueDate
          dueDate={task.tskDueDate}
          onChange={params => updateTask(params)}
          allowAlert={true}
          alertOn={task.tskAlertOn}
          alertActive={task.tskAlertActive}
        />
      </Col>
      <Col className='text-center documents'>
        {task.files.length == 0 ?
          (<span>---</span>)
          :
          (<span>{task.files.length}</span>)
        }
      </Col>
      <Col className={`state ${task.tskState}`}>

        <TaskState
          state={task.tskState}
          progress={task.tskProgress}
          onChange={params => updateTask(params)}
        />

      </Col>
      <Col className="text-center func px-3">
        <div>
          <CustomButtonsGroup>
            <CustomNewWindowBtn onClick={() => onSelect(task)} size="20" />
            <CustomDeleteBtn onClick={() => { onDelete(task) }} size="20" />
          </CustomButtonsGroup>
        </div>
      </Col>
    </Row>
  );
}
export default TasksListItem;
