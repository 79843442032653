import React from 'react';
import { BsPlusSquare, BsChevronLeft, BsChevronDoubleLeft, BsChevronDoubleRight, BsPencil, BsCheck, BsX, BsTrash, BsArrowUpDown, BsFilter } from "react-icons/bs";
import { TfiNewWindow, TfiTrash } from 'react-icons/tfi';
import './customButtons.css';

export const CustomButtonsGroup = ({ children }) => {
  return (
    <div className='custom-buttons-group'>
      {children}
    </div>
  );
};


export const CustomBackBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-back-btn ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <BsChevronLeft size={size} />
      {children}
    </span>
  );
};


export const CustomAddBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-add-btn  ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <BsPlusSquare size={size} style={{ fontSize: fontSize }} />
      {children}
    </span>
  );
};


export const CustomDeleteBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-delete-btn  ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <TfiTrash size={size} style={{ fontSize: fontSize }} />
      {hasChildren &&
        <span className='children'>{children}</span>
      }

    </span>
  );
};

export const CustomNewWindowBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-new-window-btn  ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <TfiNewWindow size={size} style={{ fontSize: fontSize }} />
      {hasChildren &&
        <span className='children'>{children}</span>
      }
    </span>
  );
};

export const CustomNextBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-next-btn  ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <BsChevronDoubleRight size={size} style={{ fontSize: fontSize }} />
      {hasChildren &&
        <span className='children'>{children}</span>
      }
    </span>
  );
};

export const CustomPreviousBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-previous-btn  ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <BsChevronDoubleLeft size={size} style={{ fontSize: fontSize }} />
      {hasChildren &&
        <span className='children'>{children}</span>
      }
    </span>
  );
};

export const CustomEditBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-edit-btn  ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <BsPencil size={size} style={{ fontSize: fontSize }} />
      {hasChildren &&
        <span className='children'>{children}</span>
      }
    </span>
  );
};

export const CustomConfirmBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-confirm-btn  ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <BsCheck size={size} style={{ fontSize: fontSize }} />
      {hasChildren &&
        <span className='children'>{children}</span>
      }
    </span>
  );
};

export const CustomCancelBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-cancel-btn  ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <BsX size={size} style={{ fontSize: fontSize }} />
      {hasChildren &&
        <span className='children'>{children}</span>
      }
    </span>
  );
};
/*
export const CustomSortBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-sort-btn  ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <BsArrowUpDown size={size} style={{ fontSize: fontSize }} />
      {hasChildren &&
        <span className='children'>{children}</span>
      }
    </span>
  );
};

export const CustomFilterBtn = ({ onClick, size = 18, fontSize = "", children, className = '' }) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <span className={`custom-btn custom-filter-btn  ${className} ${hasChildren ? 'has-children' : ''}`} onClick={onClick}>
      <BsFilter size={size} style={{ fontSize: fontSize }} />
      {hasChildren &&
        <span className='children'>{children}</span>
      }
    </span>
  );
};
*/