import React, { useEffect, useState, useRef } from 'react';
import DrawingLayer from './../helpers/drawingLayer/DrawingLayer';
import api from './../../api'
const Drawing = () => {
  const [edition, setEdition] = useState(false);
  const [imgWidth, setImgWidth] = useState(null);
  const [imgHeight, setImgHeight] = useState(null);
  const [imgNaturalWidth, setImgNaturalWidth] = useState(null);
  const [dataUrl, setDataUrl] = useState(null);
  const [canvas, setCanvas] = useState('');
  const [canvasFile, setCanvasFile] = useState(null);
  const imgRef = useRef(null);
  // const [fileId, setFileId]
  const filename = 'arbre'; // file64d67abb7a9f8
  // const filename = 'jade'; // file64d62fa2a8055
  const imgSrc = `https://app.if.an1.fr/img/tests/${filename}.jpg`;
  useEffect(() => {
    const params = {
      query: {
        'fileParentId:eq': filename,
        'fileCategory:eq': 'canvas'
      },
      limit: 1,
      contents: true,
    }
    console.log(params)
    api.get('/files', {
      params:
        params
    }).then(response => {
      console.log(response)
      if (response.data.length == 1) {
        console.log(window.atob(response.data[0].contents))
        setCanvas(JSON.parse(window.atob(response.data[0].contents)));
        setCanvasFile(response.data[0]);
      }
    });

  }, [])

  /**   FUNCTION OK   */
  const toggleEdition = () => {
    setEdition(!edition);
  };
  const handleOnUpdate = (data) => {
    if (canvasFile === null) {
      api.post('/file', {
        content: JSON.stringify(data),
        fileName: filename + '.json',
        fileCategory: 'canvas',
        fileParentId: filename,

      }).then(response => {
        setCanvasFile(response.data)
      })

    } else {
      api.put('/file/' + canvasFile.fileId, {
        contents: JSON.stringify(data),
      });

    }
    console.log(data)
  }
  /** FUNCTION A VOIR  */
  const handleImageLoad = () => {
    if (imgRef.current) {
      const { naturalWidth, naturalHeight, width, height } = imgRef.current;
      console.log(naturalWidth, width, height)
      setImgNaturalWidth(naturalWidth);
      setImgWidth(width);
      setImgHeight(height);
    }
  };


  return (
    <div className="mb-5 text-center" style={{ paddingBottom: '120px' }}>
      <p><button onClick={toggleEdition}>Toggle Édition</button></p>
      <p>{imgWidth}px X {imgHeight}px</p>
      <div className="row">
        <div className='col-2'>
        </div>
        <div className='col-8'>
          <div style={{ position: 'relative', border: '2px solid pink', textAlign: 'left' }}>
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Image à éditer"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              onLoad={handleImageLoad}
            />
            {edition && imgWidth && imgHeight && (
              <div className="image-editor-main"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: imgWidth,
                  height: imgHeight,
                  zIndex: 1,
                }}
              >
                <DrawingLayer
                  width={imgWidth}
                  height={imgHeight}
                  canvas={canvas}
                  zoom={imgWidth / imgNaturalWidth}
                  onUpdate={handleOnUpdate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="text-start">
        <textarea size='400' className="form-control" value={JSON.stringify(canvas, null, 2)} style={{ height: '800px' }}></textarea>
      </div>
    </div >
  );
};
export default Drawing;
/*                 onClear={handleOnClear}
*/