import React, { useEffect, useState, useRef } from 'react';
import './customRange.css';
const CustomRange = ({ min = 0, max = 100, step = 10, value = 0, onChange, label = 'value%', thumbColor0 = 'white', thumbBackground0 = 'blue', thumbSize = 20 }) => {
  const [percent, setPercent] = useState(100 * (value / max));
  // const [value, setValue] = useState(defaultValue);
  const [isDragging, setIsDragging] = useState(false);
  const thumbRef = useRef(null);
  const handleMouseDown = () => {
    setIsDragging(true);
  };
  useEffect(() => {
    setPercent(100 * (value / max));
  }, [value, max]);
  const handleMouseMove = (event) => {
    if (isDragging) {
      const rangeRect = event.target.parentElement.getBoundingClientRect()
      const distance = event.clientX - rangeRect.left;
      const percent = Math.min(100, Math.max(0, 100 * distance / rangeRect.width))
      setPercent(percent);
      const val = Math.round((percent / 100) * (max - min) / step) * step + min;

    }
  };
  const handleMouseUp = (event) => {
    if (isDragging) {
      const rangeRect = event.target.parentElement.getBoundingClientRect()
      const distance = event.clientX - rangeRect.left;
      const percent = Math.min(100, Math.max(0, 100 * distance / rangeRect.width))
      setPercent(percent);
      const val = Math.round((percent / 100) * (max - min) / step) * step + min;
      onChange(value)
    }
    setIsDragging(false);
  };
  const handleClick = (event) => {
    const rangeRect = event.target.parentElement.getBoundingClientRect()
    const distance = event.clientX - rangeRect.left;
    const percent = Math.min(100, Math.max(0, 100 * distance / rangeRect.width))
    setPercent(percent);
    const val = Math.round((percent / 100) * (max - min) / step) * step + min;
    // setValue(val);
    onChange(val)
  }
  const cyanColor = [0, 255, 255]; // Cyan (RVB)
  const greenColor = [0, 128, 0]; // Vert (RVB)

  const thumbColor = cyanColor.map((c, index) => {
    const delta = greenColor[index] - c;
    const interpolatedValue = c + (delta * percent) / 100;
    return Math.round(interpolatedValue);
  });

  const thumbBackground = `rgb(${thumbColor[0]}, ${thumbColor[1]}, ${thumbColor[2]})`;

  return (
    <div className="custom-range">
      <div className="custom-range-track" onMouseMove={handleMouseMove} onMouseOut={handleMouseUp} >
        <div className="custom-range-slider" onClick={handleClick}>
          <div
            className="custom-range-thumb"
            ref={thumbRef}
            style={{ left: `${percent}%`, backgroundColor: thumbBackground, color: thumbColor }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomRange;
