import React from 'react';
import ReactDOM from 'react-dom';
import BSProgressBar from 'react-bootstrap/ProgressBar';
import './progressBar.css';
const ProgressBar = ({ progress }) => {
  return ReactDOM.createPortal(
    <BSProgressBar striped variant="danger" now={progress} />
    ,
    document.getElementById('progress-bar-root') // Remplacez 'progress-bar-root' par l'ID de l'élément où vous souhaitez afficher la barre de progression
  );
};

export default ProgressBar;

/**
 * create the progress-bar wrapper
 * 
 */
const filesViewerModalRootId = 'progress-bar-root'
if (document.getElementById(filesViewerModalRootId)) {
  document.getElementById(filesViewerModalRootId).remove();
}
const filesViewerModalRoot = document.createElement('div');
filesViewerModalRoot.id = filesViewerModalRootId;
document.body.appendChild(filesViewerModalRoot);
