
import { fabric } from "fabric";

export const setEditorParams = (editor, params = {}) => {
  console.log(params)
  for (const key in params) {
    console.log(key, params[key])
    editor[key] = params[key];
    console.log(key, editor[key])
  }
}
export const addCircle = (editor, options = {}) => {
  const obj_options = {
    type: 'circle',
    radius: 10,
    stroke: editor.strokeColor,
    strokeWidth: editor.canvas.freeDrawingBrush.width,
    fill: 'transparent',
    originX: 'center',
    originY: 'center',
    positionned: true,
    ...options
  }
  const obj = new fabric.Circle(obj_options)
  editor.canvas.add(obj);
  return obj
};
export const addRect = (editor, options) => {
  const obj_options = {
    type: 'rect',
    width: 10,
    height: 10,
    stroke: editor.strokeColor,
    strokeWidth: editor.canvas.freeDrawingBrush.width,
    fill: 'transparent',
    originX: 'top',
    originY: 'left',
    positionned: true,
    ...options
  }
  const obj = new fabric.Rect(obj_options)
  editor.canvas.add(obj);
  return obj
};
export const addLine = (editor, options = {}) => {
  const obj_options = {
    type: 'line',
    stroke: editor.strokeColor,
    strokeWidth: editor.canvas.freeDrawingBrush.width,
    fill: 'transparent',
    positionned: true,
    ...options
  }
  const obj = new fabric.Line([options.left, options.top, options.left + 10, options.top + 10], obj_options)
  editor.canvas.add(obj);
  console.log('LINE', obj)
  return obj
};
export const addText = (editor, options) => {
  const obj_options = {
    fontFamily: 'sans-serif',
    fill: editor.strokeColor,
    strokeWidth: 0,
    fontSize: 10,
    textBackgroundColor: 'rgba(255,255,255,0.3)',
    positionned: true,
    ...options
  }
  const obj = new fabric.IText('Tap and Type', obj_options)
  editor.canvas.add(obj);
  return obj
}
export const deleteSelected = (editor) => {
  editor.deleteSelected();
}

export const updateSelected = (editor, key, value) => {
  if (editor) {
    editor.canvas.getActiveObjects().forEach((obj) => {
      obj.set(key, value);
    });
    editor.canvas.requestRenderAll();
  }
}


/* 
export const copy = (editor) => {

  const activeObject = editor.canvas.getActiveObject();
  let output = null
  if (activeObject) {

    activeObject.clone(function (cloned) {
      console.log(cloned)
      output = cloned;

    });
  }
  return output;


}

export const paste = (editor, copiedObjects) => { // BUG : la répétition de coller est sans effet
  copiedObjects.clone(function (clonedObj) {
    editor.canvas.discardActiveObject();
    clonedObj.set({
      left: clonedObj.left + 10,
      top: clonedObj.top + 10,
      evented: true,
    });
    console.log(clonedObj)
    editor.canvas.add(clonedObj);
  });
  editor.canvas.requestRenderAll();
}
*/