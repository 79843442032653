
import { useFormContext } from "react-hook-form";
const ReactHookFormDressing = ({ dressingData }) => {
  const { register, getValues, setValue } = useFormContext(); // Utilisez useFormContext pour obtenir le contexte du formulaire
  return (
    <>
      <label>Matière:</label>
      <input {...register(`${dressingData}.matiere`)} />
      <label>Coloris:</label>
      <input {...register(`${dressingData}.coloris`)} />

    </>
  );
};
export default ReactHookFormDressing;
