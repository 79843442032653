import axios from 'axios';
import UserService from './services/UserService';
const api_url = process.env.REACT_APP_API_URL; //'https://api.if.an1.fr';
const API = axios.create({
  baseURL: api_url,
  timeout: 30000,
});
API.getUrl = () => {
  return api_url;
}
API.getRequestHeaders = () => {
  const headers = {}
  const token = UserService.getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}
API.interceptors.request.use(
  (config) => {
    config.headers = { ...config.headers, ...API.getRequestHeaders() }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
API.interceptors.response.use(
  (response) => {

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
