import React from 'react';
import './navbars.css';
import UserService from "./../../services/UserService";
import { useNavigate } from 'react-router-dom';
const MainNavbar = () => {
  const navigate = useNavigate();
  const signOut = () => {
    UserService.signOut();
    navigate('/', { replace: true });
  }
  const handleNavigateTo = (url) => {

  }

  return (
    <nav className="navbar main-navbar">
      <div className="container-fluid">
        <div className="links left-links">

          <ul className='pictos'>
            <li onClick={() => handleNavigateTo('/map')}>
              <i className="bi bi-pin-map"></i>
              Carte
            </li>
          </ul>


        </div>
        <div className="logo">
          <a href="/main">
            <img src="/img/logo.png" alt="IF" />
          </a>
        </div>
        <div className="links right-links">
          <a className='btn btn-sm btn-secondary' onClick={signOut}>Déconnexion</a>
        </div>
      </div>
    </nav>
  );
};

export default MainNavbar;
