import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AutocompleteInput from './../form-controls/AutocompleteInput'
import api from './../../api';
import Address from '../helpers/address/Address';
const TreeFormGeneralInformations = ({ data }) => {
  /// const [treesTaxonomy, setTreesTaxonomy] = useState([]);
  const { register, getValues, setValue, watch } = useFormContext()
  const [essenceFr, setEssenceFr] = useState('')
  const [essenceLt, setEssenceLt] = useState('')
  const [address, setAddress] = useState({})
  /*
  useEffect(() => {

    api.get('/trees/taxonomy')
      .then((response) => {
        setTreesTaxonomy(response.data);
      });
  }, []);
  */


  const treesTaxonomy = useSelector(state => state.config.treesTaxonomy);

  const sortTreesTaxonomy = (key) => {
    const sortedData = [...treesTaxonomy].sort((a, b) =>
      a[key].localeCompare(b[key])
    );
    return sortedData;
  };
  const formatSuggestion = (name, suggestion) => {
    let suggestionText = '';
    switch (name) {
      case 'essenceFr':
        suggestionText = `${suggestion.essence_fr} (${suggestion.essence_lt})`; // Format personnalisé des suggestions
        break;
      case 'essenceLt':
        suggestionText = `${suggestion.essence_lt} (${suggestion.essence_fr})`; // Format personnalisé des suggestions
        break;
    }
    return suggestionText;
  };
  const handleSuggestionSelect = (name, suggestion) => {

    switch (name) {
      case 'essenceFr':
      case 'essenceLt':
        setValue(`${data}.essence_fr`, suggestion['essence_fr'])
        setEssenceFr(suggestion['essence_fr']);
        setValue(`${data}.essence_lt`, suggestion['essence_lt'])
        setEssenceLt(suggestion['essence_lt']);
        break;
      default:
        break;
    }
  }

  const handleChange = (name, value) => {
    switch (name) {
      case 'essenceFr':
        setEssenceFr(value);
        setValue(`${data}.essence_fr`, value);
        break;
      case 'essenceLt':
        setEssenceLt(value);
        setValue(`${data}.essence_lt`, value);
        break;
      case 'address':
        setValue(`${data}.address`, { ...value });
        break;
    }
  };
  return (
    <div>
      <div className='row mb-1'>
        <div className='col-md-6'>
          <div className='form-group'>
            <label className='form-group-label'>Numéro / Référence :</label>
            <input type='text'
              {...register(`${data}.reference`)}
              className='form-control'
            />
          </div>
          <div className='form-group'>
            <label className='form-group-label'>Essence :</label>
            <AutocompleteInput
              suggestionsSrc={sortTreesTaxonomy('essence_fr')}
              name='essenceFr'
              defaultValue={getValues(`${data}.essence_fr`)}
              displayKey="essence_fr"
              onChange={e => handleChange(e.target.name, e.target.value)}
              formatSuggestion={formatSuggestion}
              onSuggestionSelect={handleSuggestionSelect} />
          </div>
          <div className='form-group'>
            <label className='form-group-label'>Nom latin :</label>
            <AutocompleteInput
              suggestionsSrc={sortTreesTaxonomy('essence_lt')}
              name='essenceLt'
              defaultValue={getValues(`${data}.essence_lt`)}
              displayKey="essence_lt"
              onChange={e => handleChange(e.target.name, e.target.value)}
              formatSuggestion={formatSuggestion}
              onSuggestionSelect={handleSuggestionSelect} />
          </div>

          <div className='form-group'>
            <label className='form-group-label'>Origine :</label>
            <input type='text'
              {...register(`${data}.origine`)}
              className='form-control'
            />    </div>
          <div className='form-goup'>
            <label className='form-group-label'>Adresse :</label>
            <Address
              address={{ ...getValues(`${data}.address`) }}
              editable={true}
              onUpdate={(addr) => handleChange('address', addr)}
            />
            {<pre>{false && JSON.stringify(address, null, 2)}</pre>}
          </div>
        </div>
        <div className='col-md-6 pt-4'>
          <h5>A ajouter : </h5>
          <ul>
            <li>Section "Informations plantation"</li>
            <li>Section "Vigueur de l'arbre"</li>
            <li>Section "Autres facteurs"</li>
            <li>...</li>
          </ul>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <label className='form-group-label'>Note :</label>
          <textarea
            className='form-control'
            {...register(`${data}.comment`)} name='comment'
            onChange={handleChange}
          ></textarea>
        </div>
      </div>

    </div>
  );
};
export default TreeFormGeneralInformations;
/**/
