import React, { useState } from 'react';

// Fonction pour vérifier si une chaîne de caractères contient un sous-texte insensible à la casse et sans accents
function containsText(text, subText) {
  const normalizedText = text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const normalizedSubText = subText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return normalizedText.includes(normalizedSubText);
}

const Filter = () => {
  const [data, setData] = useState([

    { tskText: "Jade LAURENT", "tskState": "todo", tskCity: "Rouen", tskSize: "85C", tskCount: 23 },
    { tskText: "Janine BAUDÈRE", "tskState": "completed", tskCity: "Offranville", tskSize: "90A", tskCount: 157 },
    { tskText: "Stéphanie BOUTRY", "tskState": "inProgress", tskCity: "Menton", tskSize: "90B", tskCount: 397 },



  ]);
  const [filterText, setFilterText] = useState('');
  const [filterState, setFilterState] = useState([]);
  const [filterCity, setFilterCity] = useState('');
  const [filterSize, setFilterSize] = useState('');
  const [filterCountMin, setFilterCountMin] = useState(0); // Valeur minimale du filtre "Range"
  const [filterCountMax, setFilterCountMax] = useState(500); // Valeur maximale du filtre "Range"

  // Fonction de filtrage
  const filteredData = data.filter(item => {
    // Filtre par tskText
    /*
    if (filterText && !containsText(item.tskText, filterText)) {
      return false;
    }
    */
    const textToSearch = `${item.tskText} ${item.tskCity} ${item.tskSize}`;
    if (filterText && !containsText(textToSearch, filterText)) {
      return false;
    }

    // Filtre par filterState
    if (filterState.length > 0 && !filterState.includes(item.tskState)) {
      return false;
    }
    if (filterCity && !containsText(item.tskCity, filterCity)) {
      return false;
    }
    if (filterSize && !containsText(item.tskSize, filterSize)) {
      return false;
    }
    if (item.tskCount < filterCountMin || item.tskCount > filterCountMax) {
      return false;
    }

    return true;
  });

  return (
    <div>

      <div className='mb-5'>
        <div className='mb-3'>
          <label className='pe-5'>
            <input
              type="text"
              placeholder="Filtre global"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </label></div>
        <div className='mb-3'>
          <label className='pe-3'>
            <input
              type="checkbox"
              value="todo"
              checked={filterState.includes('todo')}
              onChange={(e) =>
                setFilterState(prevState =>
                  e.target.checked ? [...prevState, 'todo'] : prevState.filter(state => state !== 'todo')
                )
              }
            />&nbsp;
            Todo
          </label>
          <label className='pe-3'>
            <input
              type="checkbox"
              value="inProgress"
              checked={filterState.includes('inProgress')}
              onChange={(e) =>
                setFilterState(prevState =>
                  e.target.checked
                    ? [...prevState, 'inProgress']
                    : prevState.filter(state => state !== 'inProgress')
                )
              }
            />&nbsp;
            InProgress
          </label>
          <label className='pe-5'>
            <input
              type="checkbox"
              value="completed"
              checked={filterState.includes('completed')}
              onChange={(e) =>
                setFilterState(prevState =>
                  e.target.checked
                    ? [...prevState, 'completed']
                    : prevState.filter(state => state !== 'completed')
                )
              }
            />&nbsp;
            InProgress
          </label>
          <label className='pe-5'>
            <input
              type="text"
              placeholder="Filtrer par tskCity"
              value={filterCity}
              onChange={(e) => setFilterCity(e.target.value)}
            />
          </label>
          <label className='pe-5'>
            <input
              type="text"
              placeholder="Filtrer par tskSize"
              value={filterSize}
              onChange={(e) => setFilterSize(e.target.value)}
            />
          </label>

        </div>

        <div className='mb-3'>
          <label className='pe-3'>
            Min Count:
            <input
              type="range"
              min={0}
              max={500}
              value={filterCountMin}
              onChange={(e) => setFilterCountMin(parseInt(e.target.value, 10))}
            />&nbsp;{filterCountMin}
          </label >
          <label className='pe-5'>
            Max Count:
            <input
              type="range"
              min={0}
              max={500}
              value={filterCountMax}
              onChange={(e) => setFilterCountMax(parseInt(e.target.value, 10))}
            />&nbsp;{filterCountMax}
          </label>
        </div>







      </div>
      <ul>
        {filteredData.map(item => (
          <li key={item.tskId}>
            <strong>{item.tskText}</strong><br />
            {item.tskState}, {item.tskCity}, {item.tskCount}<br />
            --------------------------------------------------------
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Filter;
