// src/components/maps/MainMap.js
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import ModalDefault from '../modals/ModalDefault';
import MainMapLeftSide from './MainMapLeftSide';
import MarkerContextMenu from './MarkerContextMenu';
import MapContextMenu from './MapContextMenu';
import CustomMarker from './CustomMarker';
import GoogleMapService from './../../services/GoogleMapService';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaMapMarkerAlt, FaVectorSquare } from 'react-icons/fa'
import { GoogleMap, LoadScript, MarkerClusterer, Polyline } from '@react-google-maps/api';
import './mainMap.css';
import './contextMenu.css';
import api from '../../api';
import TreeForm from '../trees/TreeForm'; // Importez le composant Tree à partir du dossier "trees"
import Address from '../helpers/address/Address';
const containerStyle = {
  width: '100%',
  height: '100%',
};
const MainMap = () => {
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [mapContextMenu, setMapContextMenu] = useState({ isVisible: false, left: 100, top: 100 });
  const [markerContextMenu, setMarkerContextMenu] = useState({ isVisible: false, left: 100, top: 100 });
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [selectedTool, setSelectedTool] = useState('');
  const [alignment, setAlignment] = useState({ step: 0, count: 3 });
  const [showTreeModal, setShowTreeModal] = useState(false);
  const mapContainerRef = useRef(null);
  const [mapOptions, setMapOptions] = useState({
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    disableDefaultUI: true,
    mapTypeControl: false,
    mapTypeId: 'satellite',
    draggableCursor: '',
  })
  const [mapCenter, setMapCenter] = useState({
    lat: 47.07031164369815,
    lng: 2.3921174230956734,
  });
  const [mapZoom, setMapZoom] = useState(
    7
  );
  const [selectedTree, setSelectedTree] = useState(null); // État pour stocker les données de l'arbre sélectionné
  /* 
  GET TREES 
  */
  const getTrees = async () => {
    try {
      const response = await api.get('/trees');
      const markers = response.data;
      setMarkers(markers);
      const result = GoogleMapService.getMakersCenter(markers);
      setMapCenter({ lat: result.lat, lng: result.lng });
      setMapZoom(result.zoom);
    } catch (error) {
      console.error('Une erreur s\'est produite :', error);
    }
  };
  /* CLICK ON MAP */
  const handleMapClick = async (event) => {
    setMarkerContextMenu({
      isVisible: false,
      top: 0,
      left: 0,
    });
    const mapContainer = mapContainerRef.current;
    if (mapContainer) {
      const rect = mapContainer.getBoundingClientRect();
      const offsetX = event.domEvent.clientX - rect.left;
      const offsetY = event.domEvent.clientY - rect.top;
      setMapContextMenu({
        ...markerContextMenu,
        isVisible: true,
        top: offsetY,
        left: offsetX,
        latLng: event.latLng,
      });
    }
  };
  const createMarker = async (data) => {
    const response = await api.post('/tree', { itData: data });
    setMarkers([...markers, response.data]);
    return response.data;
  }
  const handleMapContextMenuClick = async (action, args) => {
    console.clear();
    console.log(action, mapContextMenu)
    let response = null
    switch (action) {
      case 'createTree':
        createMarker({
          lat: mapContextMenu.latLng.lat(),
          lng: mapContextMenu.latLng.lng(),
          color: 'green'
        })
        break;
      case 'createAlignment':
        const point = {
          lat: mapContextMenu.latLng.lat(),
          lng: mapContextMenu.latLng.lng(),
        }
        const update = { step: 2, coordinates: [point] };
        setAlignment({ ...alignment, ...update })
        response = await api.get('/helpers/address?lat=' + point.lat + '&lng=' + point.lng)
        if (response && response.data) {
          setAlignment({ ...alignment, ...update, address: response.data });
        }
        break;
    }
    setMapContextMenu({
      isVisible: false,
      top: 0,
      left: 0,
    });
  }
  const handleAlignmentLineClick = async (event) => {
    const point = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    const update = { step: 3 }
    setAlignment({ ...alignment, ...update })
    const response = await api.get('/helpers/address?lat=' + point.lat + '&lng=' + point.lng)
    if (response && response.data) {
      const streetNumber = alignment.address.streetNumber + ' / ' + response.data.streetNumber;
      setAlignment({ ...alignment, ...update, address: { ...alignment.address, streetNumber: streetNumber } });
    }
    return;
  }
  const handleCreateAlignment = () => {
    api.post('/trees/aligment', alignment).then(response => {
      setMarkers([...markers, ...response.data]);
    })
    setAlignment({ ...alignment, step: 0 });
  }
  /* CLICK ON MARKER */
  const handleMarkerClick = (event, index) => {
    setMapContextMenu({
      isVisible: false,
      top: 0,
      left: 0,
    });
    const mapContainer = mapContainerRef.current;
    if (mapContainer) {
      const rect = mapContainer.getBoundingClientRect();
      const offsetX = event.domEvent.clientX - rect.left;
      const offsetY = event.domEvent.clientY - rect.top;
      setMarkerContextMenu({ ...markerContextMenu, marker: markers[index], isVisible: true, top: offsetY, left: offsetX })
    }
    return;
  };
  const getSelectedTree = () => {
    return selectedTree;
  }
  /* DRAG A MARKER */
  const handleMarkerDragEnd = async (event, index) => {
    const updatedMarkers = [...markers]
    // if (selectedTool == 'markers') {
    const itData = { ...markers[index].itData, lat: event.latLng.lat(), lng: event.latLng.lng() }
    const response = await api.put(`/tree/${markers[index].itId}`, { itData });

    updatedMarkers[index] = { ...markers[index], ...response.data }

    setMarkers(updatedMarkers);
  };
  const handleMouseMove = (e) => {
    if (alignment.step == 2) {
      const currentMousePosition = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      };
      setAlignment({ ...alignment, coordinates: [alignment.coordinates[0], currentMousePosition] });
    }
  };
  /* ACTION ON TOOLBAR */
  const handleToolbarbarAction = (action) => {
    switch (action) {
      case 'refresh':
        getTrees();
        break;
      case 'center':
        if (markers.length > 0) {
          const result = GoogleMapService.getMakersCenter(markers);
          setMapCenter({ lat: result.lat, lng: result.lng });
          setMapZoom(result.zoom);
        }
        break;
    }
  }
  /* ACTION ON LEFT SIDE BAR */
  const handleLeftSideBarAction = (action) => {
    switch (action) {
      default:
    }
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Escape') {
      // Do something when "Esc" is pressed
      if (alignment.step <= 2) {
        setAlignment({ ...alignment, step: 0 });
      }
      // You can add your own code here to perform the desired action
    }
  };
  /* ACTION ON MARKER CONTEXT MENU */
  const handleMarkerContextMenuClick = async (marker, action, args) => {
    // const marker = markers.filter((m) => m.itId == markerId)[0];
    console.log(action);
    if (marker) {
      const index = markers.indexOf(marker);
      switch (action) {
        case 'toggleLock':
          marker.unlocked = marker.unlocked == true ? false : true;
          if (marker.unlocked) {
            window.setTimeout(() => {
              handleMarkerContextMenuClick(marker, 'toggleLock')
              marker.unlocked = false;
            }, 3000);
          }
          break;
        case 'edit':
          const resp = await api.get('/tree/' + marker.itId);
          setSelectedTree(resp.data);
          setShowTreeModal(true);
          break;
        case 'delete':
          api.delete(`/tree/${marker.itId}`)
            .then(response => {
              const updatedMarkers = markers.filter(m => marker.itId !== m.itId);
              setMarkers(updatedMarkers);
            })
            .catch(error => {
            });
          break;
        case 'duplicate':
          const newMarker = { ...markers[index] };
          delete newMarker.itId;
          newMarker.itData.lat += -0.00005;
          newMarker.itData.lng += 0.00005;
          let response = await api.post('/tree', newMarker);
          setMarkers([...markers, response.data]);
          break;
        case 'color':
          api.put(`/tree/${marker.itId}`, { itData: { ...marker.itData, color: args } })
            .then(response => {
              const markers_copy = [...markers];
              markers_copy[index] = { ...marker, ...response.data };
              setMarkers(markers_copy);
            })
            .catch(error => {
            });
          break;
      }
    }
    setMarkerContextMenu({
      isVisible: false,
      top: 0,
      left: 0,
    });
  };
  /* ACTIONS SUR LA TOOLBAR */
  const handleSelectTool = (tool) => {
    setSelectedTool(selectedTool !== tool ? tool : '');
  }
  const handleOnTreeUpdated = (tree) => {
    const index = markers.findIndex((m) => m.itId === tree.itId);
    if (index !== -1) {
      const markers_copy = [...markers];
      markers_copy[index] = tree;
      setMarkers(markers_copy);
    }
    setSelectedTree(tree);
    // setShowTreeModal(false);
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    getTrees();
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  const onZoomChanged = (event) => {
    setMapContextMenu({ ...setMapContextMenu, isVisible: false })
    setMarkerContextMenu({ ...markerContextMenu, marker: null, isVisible: false })
  }
  const onCenterChanged = (event) => {
    setMapContextMenu({ ...setMapContextMenu, isVisible: false })
    setMarkerContextMenu({ ...markerContextMenu, marker: null, isVisible: false })
  }
  /** 
   * TREE FORM 
  */
  const treeFormRef = useRef(null);
  const handleSaveTreeButtonClick = () => {
    treeFormRef.current.handleSave();
  }
  useEffect(() => {
  }, [markers]);
  const handleCloseTreeModal = () => { setShowTreeModal(false); }
  const clusterOptions = {
    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    gridSize: 100,
    maxZoom: 16,
    styles: [
      {
        textColor: 'white',
        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
        height: 53,
        width: 53,
      },
    ],
  };
  return (
    <div className='main-map'>
      <MainMapLeftSide onClick={handleLeftSideBarAction} />
      <div className='map' ref={mapContainerRef}>
        <LoadScript googleMapsApiKey="AIzaSyAiJwKvBJzvCh3fTtYOsQTocidyjyGjgCE">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={mapZoom}
            onClick={handleMapClick}
            ref={mapRef}
            options={mapOptions}
            onZoomChanged={onZoomChanged}
            bounds={bounds}
            onCenterChanged={onCenterChanged}
            onMouseMove={(event) => handleMouseMove(event)}
          >
            <MarkerClusterer options={clusterOptions}>
              {(clusterer) =>
                markers.map((marker, index) => (
                  <CustomMarker
                    key={marker.itId}
                    color={marker.itData.color}
                    position={marker.itData}
                    onClick={(event) => handleMarkerClick(event, index)}
                    onDragEnd={(event) => handleMarkerDragEnd(event, index)}
                    clusterer={clusterer}
                    unlocked={marker.unlocked == true}
                  />
                ))
              }
            </MarkerClusterer>
            {alignment.step > 0 &&
              <Polyline
                path={alignment.coordinates}
                options={{ strokeColor: 'red' }}
                onClick={handleAlignmentLineClick}
              />
            }
          </GoogleMap>
          {mapContextMenu.isVisible && (
            <MapContextMenu
              top={mapContextMenu.top}
              left={mapContextMenu.left}
              onClick={handleMapContextMenuClick}
            />
          )}
          {markerContextMenu.isVisible && (
            <MarkerContextMenu
              marker={markerContextMenu.marker}
              top={markerContextMenu.top}
              left={markerContextMenu.left}
              onClick={handleMarkerContextMenuClick}
            />
          )}
        </LoadScript>
        <div className='map-toolbar'>
          <ul>
            <li>
              <select className='form-control form-control-sm' onChange={(e) => { setMapOptions({ ...mapOptions, mapTypeId: e.target.value }); }} value={mapOptions.mapTypeId}>
                <option value='terrain'>Plan</option>
                <option value='satellite'>Satellite</option>
              </select>
            </li>
            <li >
              <button className='btn btn-sm btn-secondary' onClick={() => handleToolbarbarAction('center')}>Recenter</button>
            </li>
            <li >
              <button className='btn btn-sm btn-secondary' onClick={() => handleToolbarbarAction('refresh')}>Rafraichir</button>
            </li>
            <li className='tools'>
              <button className={`btn btn-sm btn-secondary ${selectedTool === 'markers' ? 'selected' : ''}`} onClick={() => handleSelectTool('markers')}>
                <FaMapMarkerAlt />
              </button>
              <button className={`btn btn-sm btn-secondary ${selectedTool === 'drawing' ? 'selected' : ''}`} onClick={() => handleSelectTool('drawing')}>
                <FaVectorSquare />
              </button>
            </li>
            <li >
              {markers.length}
            </li>
          </ul>
        </div>
        <Modal
          show={showTreeModal}
          onHide={handleCloseTreeModal}
          backdrop="static"
          size="xl"
          keyboard={false}
          dialogClassName="modal-full-height"
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedTree ? selectedTree.itData.reference : ''}&nbsp;</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TreeForm tree={getSelectedTree()} onSaved={handleOnTreeUpdated}></TreeForm>
          </Modal.Body>
        </Modal>
        <Modal
          show={alignment.step == 3}
          onHide={() => setAlignment({ ...alignment, step: 0 })}
          backdrop="static"
          size="md"
          keyboard={false}
          dialogClassName=""
        >
          <Modal.Header closeButton>
            <Modal.Title>Création d'un alignement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="form-group-label"><strong>Nombre total de sujets :*</strong></label>
              <input type="number" value={alignment.count} onChange={e => setAlignment({ ...alignment, count: e.target.value })} className='form-control text-center' style={{ width: 120 }} />
            </div>
            <div className="form-group">
              <label className="form-group-label">Nom de l'alignement :</label>
              <input disabled type="text" placeholder="(inactif pour le moment)" value={alignment.name} onChange___={e => setAlignment({ ...alignment, name: e.target.value })} className='form-control' />
            </div>
            <div className="form-group">
              <label className="form-group-label">Adresse :</label>
              <Address address={alignment.address} editable={true} onUpdate={addr => setAlignment({ ...alignment, address: addr })} />
            </div>
            <div className="form-group text-end">
              <button type="button" className="btn btn-secondary" onClick={handleCreateAlignment}>Valider</button>
              <button type="button" className="btn btn-default" onClick={() => setAlignment({ ...alignment, step: 0 })}>Annuler</button>
            </div>
          </Modal.Body>
        </Modal>
        {false &&
          <div className='alert alert-success position-absolute' style={{ bottom: 0, left: 0 }}>
            <pre>{JSON.stringify(alignment, null, 2)}</pre>
          </div>
        }
      </div>
    </div >
  );
};
export default MainMap;
