// login.js
import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import UserService from "../../services/UserService";
import "./styles.css"
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeForm, setActiveForm] = useState("signIn");
  const [submission, setSetSubmission] = useState({ status: 'error', message: '' });
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  console.clear();


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {};
    setSetSubmission({ ...submission, message: '' })
    try {
      const email = emailRef.current.value.trim();
      if (email == '') {
        throw ('Tous les champs sont requis !');
      }
      data.email = email;
      if (activeForm === 'signIn' || activeForm === 'signUp') {
        const password = passwordRef.current.value.trim();
        if (password == '') {
          throw ('Tous les champs sont requis !');
        }
        data.password = password;
      }
      if (activeForm === 'signUp') {
        const confirmPassword = confirmPasswordRef.current.value.trim();
        if (confirmPassword == '') {
          throw ('Tous les champs sont requis !');
        }
        data.confirmPassword = confirmPassword;
      }
      const response = await UserService[activeForm](data);
      if (response) {
        const searchParams = new URLSearchParams(location.search);

        if (searchParams.get("path")) {
          navigate(searchParams.get("path"), { replace: true });
        } else {
          navigate('/main', { replace: true });
        }



      }


    } catch (error) {



      setSetSubmission({ ...submission, message: error.response.data.error.message })
    }

    // Add login logic here
  };
  const handleSetActiveForm = (name) => {
    // emailRef.current.value = '';
    // passwordRef.current.value = '';
    // confirmPasswordRef.current.value = '';
    setActiveForm(name);
  };
  return (
    <div id="login">

      <form noValidate>
        <div className="card">
          <div className="card-header">
            <h5>Connexion</h5>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label className="form-group-label">Email</label>
              <input className="form-control" type="email" ref={emailRef} required defaultValue="tech@animanet.fr" />
            </div>
            {(activeForm === "signIn" || activeForm === "signUp") && (
              <div className="form-group">
                <label className="form-group-label">Password</label>
                <input className="form-control" type="password" ref={passwordRef} required defaultValue="whitebra" />
              </div>
            )}
            {activeForm === "signUp" && (
              <div className="form-group">
                <label className="form-group-label">Password</label>
                <input className="form-control" type="password" ref={passwordRef} required />
              </div>)}
            {submission.message !== "" && (
              <div className="form-group submission-status">
                <div className={'alert alert-sm alert-' + (submission.status == 'error' ? 'danger' : submission.status)}>
                  {submission.message}
                </div>
              </div>)}
            <div className="text-center">
              <button className="btn btn-secondary" type="button" onClick={handleSubmit} >
                {activeForm === "signIn" && (
                  <span>Connexion</span>
                )}
                {activeForm === "signUp" && (
                  <span>Créer mon compte</span>
                )}
                {activeForm === "lostPwd" && (
                  <span>Réinintialiser mon mot de passe</span>
                )}
              </button>
            </div>
            <div className="text-center">
              {(activeForm === "signIn") && (
                <button className="btn btn-sm btn-default" type="button" onClick={() => handleSetActiveForm("signUp")} disabled>
                  Créer un compte
                </button>
              )}
              {(activeForm === "signIn") && (
                <button className="btn btn-sm btn-default" type="button" onClick={() => handleSetActiveForm("lostPwd")} disabled>
                  Mot de passe oublié
                </button>
              )}
              {(activeForm !== "signIn") && (
                <button className="btn btn-sm btn-default" type="button" onClick={() => handleSetActiveForm("signIn")}>
                  Annuler
                </button>
              )}
            </div>
          </div>
        </div>
        <p style={{ display: 'none' }}>Current form: {activeForm}</p>
      </form >
    </div >
  );
};
export default Login;
